import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Menu from "./menu";
import moment from "moment";
import {
	Col,
	Container,
	Row,
	Table,
	Button,
	InputGroup,
	FormControl,
	Image,
	Form,
} from "react-bootstrap";
import Conference from "../img/conference.jpeg";
import { useEffect } from "react";
import { useState } from "react";
import AuthContext from "../context/authprovider";
import { useContext } from "react";
import MyButton from "./mybutton";
export default function EventResource(props) {
	const { auth } = useContext(AuthContext);
	// let navigate= useNavigate();
	// props.type!=="TCTC" && props.type!=="NTCP" && props=="2" ? navigate(`${props.id}`): null;
	let params = useParams();
	const [conference, setConference] = useState({});
	const [session, setSession] = useState(0);
	const [sessionName, setSessionName] = useState(0);
	const [sessionRecording, setSessionRecording] = useState("");
	const [sessionResources, setSessionResources] = useState([]);
	const fullStyle = {
		marginTop: "-30px",
		// backgroundImage:`url(${Webinar})`,
		minHeight: "calc(100vh - 300px)",
		// backgroundSize:"cover",
		// backgroundPosition:"center",
		position: "relative",
	};
	const titleStyle = {
		width: "100%",
		color: "white",
		fontSize: "40px",
		fontWeight: "bold",
		padding: "10px",
		backgroundColor: "rgba(150,150,150,.4)",
		marginBottom: "30px",
		backdropFilter: "blur(10px)",
		boxShadow: "2ps 2px #ff0000",
	};

	const imageStyle = {
		position: "absolute",
		height: "100%",
		width: "100%",
		top: "0",
		left: "0",
		zIndex: "-2",
		backgroundImage: `url(${Conference})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
	};
	const blurStyle = {
		backdropFilter: "blur(4px)",
		position: "absolute",
		height: "100%",
		width: "100%",
		top: "0",
		left: "0",
		zIndex: "-1",
	};
	const containerStyle = {
		zIndex: "2",
	};
	const headerStyle = {
		marginBottom: "0",
		color: "blue",
		fontSize: "15px",
	};
	const infoStyle = {
		marginTop: "0",
		fontWeight: "bold",
		// fontSize:"25px"
	};
	const nameStyle = {
		marginTop: "-15px",
		color: "rgb(0,128,115)",
		fontWeight: "bold",
	};
	const resourceStyle = {
		backgroundColor: "rgb(0,128,115)",
		width: "100%",
		padding: "10px",
		boxSizing: "border-box",
		color: "white",
		fontWeight: "Bold",
		fontSize: "25px",
		border: "1px white solid",
		boxShadow: "1px 1px 5px grey",
		marginTop: "10px",
		marginBottom: "10px",
	};
	const handleClick = (sessionid, sessionname) => {
		setSession(sessionid);
		setSessionName(sessionname);
		fetch(`https://www.api.tctcollab.com/resources/session/${sessionid}`)
			.then((response) => response.json())
			.then((data) => {
				// setConference(data);
				var recording = data.find((item) => item.typeid === 1);
				if (recording !== undefined) {
					setSessionRecording(recording.resource_url);
				} else {
					setSessionRecording("");
				}
				setSessionResources(data);
				// <iframe width="560" height="315" src="https://www.youtube.com/embed/HTO36RmmJHw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			});
	};
	const handleClickBack = () => {
		setSession(0);
	};
	const trackClick = (resourceid) => {
		let data = {
			userid: auth.userid,
			resourceid,
		};
		fetch(`https://www.api.tctcollab.com/tracking/resourceclick`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((results) => results.json())
			.then((data) => {});
	};
	useEffect(() => {
		fetch(`https://www.api.tctcollab.com/resources/conference/${params.id}`)
			.then((response) => response.json())
			.then((data) => {
				setConference(data);
				// console.log(data);
			});
	}, []);
	return (
		<div style={fullStyle}>
			{conference.hasOwnProperty("name") && (
				<div>
					<div style={titleStyle}>{conference.name}</div>
					<div style={imageStyle}></div>
					<div style={blurStyle}></div>
					<Container style={containerStyle}>
						<Row>
							<Col xl="3">
								<Menu bgColor="white">
									<div style={{ textAlign: "left" }}>
										<p className="header-text" style={headerStyle}>
											Date:
										</p>
										<p className="info" style={infoStyle}>
											{moment(conference.date).format("MMMM DD YYYY")}{" "}
										</p>
										{session !== 0 && (
											<div>
												<p className="header-text" style={headerStyle}>
													Session:
												</p>
												<p className="info" style={infoStyle}>
													{sessionName}
												</p>
											</div>
										)}
										{/* <p className="names" style={nameStyle}>Sarah Moreland-Ruseell | Kim Prewitt</p> */}
									</div>
								</Menu>
								<div></div>
								{session !== 0 && (
									<Menu bgColor="white">
										<MyButton
											variant="purple"
											style={{ width: "100%" }}
											onClick={() => handleClickBack()}
										>
											Go Back to Sessions
										</MyButton>
									</Menu>
								)}
							</Col>
							<Col xl="9">
								{session === 0 ? (
									conference.sessions.map((session) => {
										return (
											<div
												className="resource-button"
												style={resourceStyle}
												onClick={() => {
													// trackClick(session.sessionid);
													handleClick(session.sessionid, session.name);
												}}
											>
												{session.name}
											</div>
										);
									})
								) : (
									<div className="session-container">
										{sessionRecording !== "" && (
											<div className="recording-container">
												<iframe
													width="950"
													height="620"
													src={sessionRecording}
													title="YouTube video player"
													frameborder="0"
													allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
													allowfullscreen
												></iframe>
											</div>
										)}
										{sessionResources.map((resource) => {
											return (
												<div
													className="resource-button"
													style={resourceStyle}
													onClick={() => {
														trackClick(resource.resourceid);
														window.open(`${resource.resource_url}`, "_blank");
													}}
												>
													{resource.resource_name}
												</div>
											);
										})}
									</div>
								)}
							</Col>
						</Row>
					</Container>
				</div>
			)}
		</div>
	);
}
