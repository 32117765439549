import React from "react";
import { Button } from "react-bootstrap";
import MyButton from "./../mybutton";
import { Form, Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useContext, useEffect } from "react";
import CoPLogo from "../../img/coplogo.png";
import AuthContext from "../../context/authprovider";
import LSSLogo from "../../img/LSSlogo.png";
export default function CoP(props) {
	let params = useParams();
	const { auth, setAuth } = useContext(AuthContext);
	const [npm, setNpm] = useState(true);
	const [yc, setYc] = useState(false);

	// const [password,setPassword]=useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [message, setMessage] = useState("");
	const style = {
		maxWidth: "800px",
		textAlign: "center",
		margin: "auto",
		boxShadow: "5px 5px 5px grey",
	};
	useEffect(() => {
		let saved = localStorage.getItem("user");
		if (saved && JSON.stringify(auth) === "{}") {
			setAuth(JSON.parse(saved));
		}
		// console.log(auth.userid);
	}, []);

	useEffect(() => {}, []);
	return (
		<div className="steps-container">
			<div style={{ marginBottom: "20px" }}>
				<Container>
					<Row>
						<div
							style={{
								width: "100%",
								// backgroundColor: "black",
								textAlign: "center",
								marginBottom: "10px",
							}}
						>
							<img
								style={{ width: "20%", textAlign: "center" }}
								src={LSSLogo}
							/>
						</div>
						<h2 className="page-header-profiles">Leadership Profiles</h2>
						<div className="all-person-container">
							<div className="person-container">
								<div className="picture-container"></div>
								<div className="profile-container">
									<div className="header-container">
										<div className="profile-name">Priscilla Bennett</div>
										<div className="profile-org">
											Arizona Department of Health Services
										</div>
									</div>
									<div className="question-container">
										Primary Role and Responsibilities
									</div>
									<div className="answer-container">
										<p>
											As the Tobacco Cessation Consultant for the Arizona
											Department of Health Services, I oversee the state's
											contract with National Jewish Health, the vendor for
											Arizona Smokers’ Helpline (ASHLine) commercial tobacco
											cessation services. I provide expertise in tobacco
											cessation interventions and services, ensuring alignment
											with research and best practices, and collaborate with
											National Jewish Health to promote health systems change by
											engaging advocates and healthcare providers to enhance
											ASHLine visibility and connect with populations facing
											disparities.
										</p>
									</div>
									<div className="question-container">
										Most Valuable Leadership Strength
									</div>
									<div className="answer-container">
										<p>
											I believe my most valuable leadership strength is my
											capacity for empathy and relationship orientation. I find
											it really important in my work to listen to others, seek
											diverse perspectives, and foster collaboration within
											teams in order to build trust and inclusivity. I also find
											that a dedication to integrity, responsibility, and
											accountability aligns with building trust within teams and
											organizations, and is something I try to prioritize in my
											daily life and work.
										</p>
									</div>
									<div className="question-container">
										Leadership Skill I Hope to Strengthen
									</div>
									<div className="answer-container">
										<p>
											Self-awareness, in the sense that I would like to be
											better about thoughtfully engaging in opportunities to
											reflect on experiences, actively seeking feedback from
											diverse perspectives and cultivate a deeper understanding
											of my strengths, weaknesses, and motivations. This will
											enable me to adapt more effectively to complex and
											uncertain situations, enhance my leadership effectiveness,
											and foster continuous personal and professional growth.
										</p>
									</div>
									<div className="question-container">
										Favorite Leadership Quote
									</div>
									<div className="answer-container">
										<p>
											“You cannot get through a single day without having an
											impact on the world around you. What you do makes a
											difference, and you have to decide what kind of difference
											you want to make.” - Jane Goodall
										</p>
									</div>
									<div className="question-container">
										Person(s) I Most Look Up to in the Current Tobacco Control
										Movement
									</div>

									<div className="answer-container">
										<p>
											I would have to say our office’s Program Manager, Emily
											Carlson. She remains incredibly motivated and enthusiastic
											in her role, and leads by example. She is committed to
											serving the people of Arizona, and allows that to guide
											her work.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="all-person-container">
							<div className="person-container">
								<div className="picture-container"></div>
								<div className="profile-container">
									<div className="header-container">
										<div className="profile-name">Michele Bowles</div>
										<div className="profile-org">
											West Virginia Division of Tobacco Prevention
										</div>
									</div>
									<div className="question-container">
										Primary Role and Responsibilities
									</div>
									<div className="answer-container">
										<p>
											As the Tobacco Cessation Coordinator, my primary role is
											to oversee and coordinate all tobacco cessation services
											throughout our Division. A secondary function of this
											position involves administrative tasks related to managing
											sub-grantee work, assisting with the development and
											evaluation of programs, and building collaborative
											partnerships that will strengthen tobacco control efforts.
										</p>
									</div>
									<div className="question-container">
										Most Valuable Leadership Strength
									</div>
									<div className="answer-container">
										<p>
											An effective leader must recognize the need to create
											strong working relationships. There is power in numbers. I
											have learned over the years that building networks,
											engaging key stakeholders, and creating an inviting
											environment only benefits the outcomes we are striving to
											achieve.
										</p>
									</div>
									<div className="question-container">
										Leadership Skill I Hope to Strengthen
									</div>
									<div className="answer-container">
										<p>
											I hope to strengthen my verbal communication skills and
											build confidence through my participation in the LS&S.
										</p>
									</div>
									<div className="question-container">
										Favorite Leadership Quote
									</div>
									<div className="answer-container">
										<p>
											"Leaders think and talk about the solutions. Followers
											think and talk about the problems." - Brian Tracy. I like
											this quote because it reminds me that only true change can
											occur when we put forth the effort.
										</p>
									</div>
									<div className="question-container">
										Person(s) I Most Look Up to in the Current Tobacco Control
										Movement
									</div>

									<div className="answer-container">
										<p>
											There are so many national leaders and advocates who I
											consider phenomenal when it comes to their work in tobacco
											control. Unfortunately, I haven't been in my position long
											enough to have had a personal and remarkable experience
											with any one of those individuals (although I look forward
											to the opportunities in the future). However, I must admit
											that I greatly admire those individuals who work on a
											grass root level; the ones who retire from their day job
											yet are so passionate about the issue that they continue
											fighting on their own time; and the community members who
											make a choice every day to fight the uphill battle of
											improving the health of our fellow West Virginians. I
											consider those individuals strong and inspirational
											leaders.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="all-person-container">
							<div className="person-container">
								<div className="picture-container"></div>
								<div className="profile-container">
									<div className="header-container">
										<div className="profile-name">Mary Brush</div>
										<div className="profile-org">
											Massachusetts Tobacco Cessation and Prevention Program
										</div>
									</div>
									<div className="question-container">
										Primary Role and Responsibilities
									</div>
									<div className="answer-container">
										<p>
											In my position as Community Liaison, I oversee and support
											the work of the funded local Board of Health tobacco
											programs to enact and enforce policies, systems, and
											environmental changes that reduce tobacco use, protect the
											public from secondhand smoke, and improve local public
											health. My primary responsibilities include:
											<ul>
												<li>
													Supporting and building capacity of local grantees as
													they develop, plan, and implement their programs to
													meet scope of service.
												</li>
												<li>
													Working with staff and grantees as a team to create
													work plans and reporting templates
												</li>
												<li>
													Collaborating with staff, local program grantees, and
													internal and external partners in efforts to address
													structural racism and the social determinants of
													health as they contribute to tobacco use and exposure.
													Assisting with planning of training and technical
													assistance development for grantees.
												</li>
												<li>
													Developing and planning agendas and facilitating
													meetings of staff, program grantees and local
													partners.
												</li>
											</ul>
										</p>
									</div>
									<div className="question-container">
										Most Valuable Leadership Strength
									</div>
									<div className="answer-container">
										<p>
											The most valuable leadership skill I possess is the
											ability to be flexible. Working in community I have
											learned to be open minded and quickly adjust to
											circumstances and team dynamics. Flexibility helps me to
											adapt to changes in project or stakeholders' expectations
											so we can adjust strategies while still achieving goals.
											Flexibility allows me to be responsive to a program's
											needs and builds trust and shares power with team members
											or communities. Creative things happen when a leader can
											be flexible.{" "}
										</p>
									</div>
									<div className="question-container">
										Leadership Skill I Hope to Strengthen
									</div>
									<div className="answer-container">
										<p>
											Through participation in the Leadership and Sustainability
											School I hope to improve my strategic planning and
											visioning skills. Bigger picture thinking is so important
											but can be daunting and overwhelming. Acquiring new skills
											to help make it manageable would be beneficial to bring
											back to support the advancement of the goals and
											priorities of the Massachusetts Tobacco Cessation and
											Prevention Program.
										</p>
									</div>
									<div className="question-container">
										Favorite Leadership Quote
									</div>
									<div className="answer-container">
										<p>
											“The main thing is to keep the main thing the main
											thing..” - Stephen Covey
										</p>
										<p>
											I love this quote because it reminds me to keep the focus
											on what is most important. We can get lost in the noise of
											a project or tasks but it is key to stay focused on the
											goal. This thought pulls you back and serves as a
											reminder. I also love the simplicity of the statement.
										</p>
									</div>
									<div className="question-container">
										Person(s) I Most Look Up to in the Current Tobacco Control
										Movement
									</div>

									<div className="answer-container">
										<p>
											I look up to all the local people and programs that are
											working tirelessly on tobacco control in their
											communities. They are on the front lines of addressing
											tobacco industry tactics and with their intimate knowledge
											of their communities they are so effective at creating
											policy, systems, and environmental changes to protect
											their residents. I learn a lot from the folks in the field
											and by following their lead.{" "}
										</p>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">McKenna Christensen</div>
											<div className="profile-org">
												Utah Tobacco Prevention and Control Program
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												As the policy analyst for my team, my work focuses on
												all things policy. During the legislative session, I
												track current legislation, provide feedback on draft
												bills as requested, and contribute to fiscal note
												analysis. Outside of the legislative session, I provide
												policy support to partners, write and amend rules as
												needed, and participate in various policy discussions
												and workgroups.
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>
												The most valuable leadership strength I possess is my
												problem solving abilities.
											</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>
												I most hope to strengthen my confidence through
												participating in the L&SS.
											</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>
												"Leadership and learning are indispensable to each
												other." - John F. Kennedy
											</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												I admire the youth advocates working in the tobacco
												control movement, particularly those within my state. It
												is encouraging to me to see the future generation of
												tobacco control leaders already making such a
												difference, including their influence on legislation.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">Leslie DeHart, MPH</div>
											<div className="profile-org">
												Oklahoma State Department of Health
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												I am the coordinator overseeing our work with
												contractors addressing disparities and our work on clean
												indoor air policies and promotion. I oversee our tobacco
												control consultants who work with a variety of
												contractors on targeted and special projects.
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>
												I believe I am particularly skilled in staff
												development. I look for ways to coach and mentor staff
												who are new to our program and assist them in growing as
												public health professionals. This means having difficult
												conversations at times but I believe even those
												conversations can be held with a focus on strengths and
												building upon those areas.
											</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>
												I am hoping to strengthen my ability to network not only
												with others in this work but to advance my ability to
												see the ways our programs interconnect and where we can
												network. I am fairly new to tobacco control (2 years)
												and am still learning to recognize how we can all work
												together and am developing the skill of identifying ways
												to partner.
											</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>
												"People would rather follow a leader that is real than
												one that is always right." - Craig Groeschel
											</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												I promise I’m not reaching for brownie points here but I
												most look up to our program manager, Elizabeth
												Chery-Mullen. She sees the ways each of our pillars
												interconnect and understands the nuances of policies at
												the state and federal level and how they will affect
												different pieces of our work. She leads with compassion
												but holds her staff to a high standard of quality and
												professionalism.{" "}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">Chioma Ejiofor</div>
											<div className="profile-org">
												Chicago Department of Public Health
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												I am a public health administrator III at CDPH. My role
												oversees strategic planning of all CDPH tobacco
												programs. As the CDPH tobacco program is multi-faceted,
												my primary responsibility is ensure that all program
												objectives and strategies are in alignment. I also lead
												the department’s tobacco cessation work, which works
												with delegate agencies to offer cessation services to
												Chicago residents. In both areas I also oversee
												administrative functions ensuring workplans, budgets and
												reports are on track.
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>
												My most valuable leadership strength is my continual
												strive for growth. Growth not just in programs and
												numbers but growth in ability as a team.{" "}
											</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>
												The leadership skill I hope to strengthen is my ability
												to delegate appropriately.{" "}
											</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>
												“If your actions inspire others to dream more, learn
												more, do more and become more, you are a leader” - John
												Quincy Adams
											</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												I am new to the tobacco control movement but in my short
												time here the people who inspire me most are the
												community health workers we partner with. They are boots
												on the ground sharing the message in community about the
												harms of tobacco and pointing them towards quitting
												resources. It’s through them that I understand and feel
												the true impact that tobacco has had and is having on
												our communities.{" "}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">Marilyn Headley</div>
											<div className="profile-org">
												CA Tobacco Prevention Program at the California Dept. of
												Public Health
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												primary responsibilities in that role. As a policy
												consultant I’m primarily responsible for monitoring,
												analyzing, and assessing the impact of potential state
												and federal tobacco control legislation on our program.
												I also provide technical assistance such as education
												and consultation on the implementation of federal,
												state, and local laws.{" "}
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>My most valuable leadership skill is integrity.</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>
												I hope to strengthen collaboration and equity leadership
												skills.{" "}
											</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>
												“The self is not something ready-made, but something in
												continuous formation through choice of action.” - John
												Dewey
											</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												I most admire the people in their communities doing the
												hard work of policy passage and community organizing.
												The people on the ground - whom I once was and know what
												it’s like - are the hardest working and committed people
												in the movement.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">Jennifer Herd</div>
											<div className="profile-org">
												Chicago Department of Public Health
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												My current primary role is to serve as the Department’s
												policy subject matter expert in the chronic disease
												areas of tobacco prevention and cessation and food
												insecurity and to develop with partners systems
												improvement solutions for these areas.
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>
												Identifying pragmatic opportunities and responsively
												connecting the dots.
											</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>
												The art of better consensus building by improving my
												ability to articulate and summarize complex and varied
												positions.
											</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>
												“Real change, enduring change, happens one step at a
												time” - RBG. Additionally, although not directly a
												leadership-related quote, I believe Margaret Meade’s
												quote: “Never underestimate the power of a small group
												of committed people to change the world. In fact, it is
												the only thing that ever has” is also important to
												highlight, as it demonstrates the importance of good
												leadership and the importance of consensus building,
												which leads to one of my most favorite leadership
												quotes: "A genuine leader is not a searcher for
												consensus, but a molder of consensus." - Rev. Martin
												Luther King, Jr.
											</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												This request is tough one because there have been so
												many instrumental leaders over the years and although
												the question asked for “current”, I first need to
												acknowledge the importance of Tom Frieden, the former
												CDC Director and Commissioner of the NYC Department of
												Public Health, as the leadership he executed at NYC and
												then was expanded nationally, through his appointment to
												the CDC, was ultimately instrumental in smoking being
												removed from nearly all public places in the United
												States. It started on the coasts and then progressively
												moved to the interior states over time. However, to be
												responsive to the question, my current tobacco control
												movement leader I look up to is Carol McGruder of the
												African American Tobacco Control Leadership Council
												(AATCLC). She has been a tenacious advocate for menthol
												to be added to the FDA’s definition of flavored tobacco
												and she continues the fight despite the recent federal
												setbacks.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">Kara Backer</div>
											<div className="profile-org">
												North Dakota Tobacco Prevention and Control Program
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												As the Nicotine Dependence Treatment Coordinator, I
												manage a cessation grant program that assists health
												systems and organizations in developing and expanding
												tobacco and nicotine dependence treatment. Part of this
												work is creating grant guidance based on our program
												goals, understanding grantees’ capacity, assisting
												grantees with budget planning, and determining data
												points, which are essential for the grant program.
											</p>
											<p>
												I provide technical assistance or plan events to enhance
												grantee interventions, such as in-state tobacco
												treatment specialist (TTS) training, educator training
												on nicotine dependence in students, a motivational
												interviewing workshop, nicotine dependence conferences,
												and ongoing webinar education. Since 2018, we’ve trained
												over 350 TTS and hosted approximately 30 education
												events.
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>
												My valuable leadership strength is critical thinking. I
												find enjoyment in reviewing data and using information
												to make decisions. Tobacco control can be a sensitive
												subject in government, and data supports our work and
												program funding.
											</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>
												The leadership skill I hope to strengthen is
												flexibility. When I review reporting, I like to offer
												feedback or offer suggestions. However, it can be
												challenging for me to accept last-minute changes or
												roadblocks.
											</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>
												“Innovation distinguishes between a leader and a
												follower.” - Steve Jobs
											</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												North Dakota took a stance against ENDS use when there
												was no consensus on the products and before people were
												promoting the products as harm reduction. Through his
												initiation, our program created an ENDS Position
												Statement in September 2018, before the US Surgeon
												General called vaping an epidemic.
											</p>
											<p>
												Several years ago, he engaged our program in a
												sustainability training. Following that, our program
												produces a biennial report, which is vital to
												demonstrate our program data and activities. The result
												is our Legislature maintaining or increasing our program
												funding to continue or expand tobacco prevention and
												control efforts.
											</p>
											<p>
												He has become actively involved in several boards and
												committees on a national level and is promoting evolving
												terminology - treatment of commercial tobacco versus
												cessation - and treatment as a policy. Neil is
												thoughtful in his work, and under his guidance, our work
												in North Dakota has been recognized and emulated.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">Christy Knight</div>
											<div className="profile-org">
												State of Alaska, Department of Health, Division of
												Public Health, Tobacco Prevention and Control Program
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												As the manager for the State of Alaska’s Tobacco
												Prevention and Control Program, I coordinate and
												leverage resources and partnerships to administer a
												comprehensive tobacco control program. I provide
												oversight, mentorship, and coaching to state staff. I
												oversee many administrative responsibilities of the
												program, including budgeting, procurement, and reporting
												requirements.
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>
												I am a very collaborative leader who enjoys building
												teams.
											</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>
												I hope to strengthen my ability to inspire and energize
												partners and team members in advancing our goals.
											</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>
												"A sign of a good leader is not how many followers you
												have, but how many leaders you create." - Mahatma Gandhi
											</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												I admire many people within the tobacco control
												movement. My answer depends on the day and the energy
												that I need to draw upon. The person who came to mind
												today was Dr. Brian King. I always admire his ability to
												communicate complex information and inspire action.
												Recently, I admired his decision to take on the role of
												Director for the Food and Drug Administration’s Center
												for Tobacco Products during a tumultuous time. I’ve
												appreciated the agency’s clear, fair, and science-based
												actions regarding the premarket authorization process
												under his direction. It is important to advocate for
												change in government. I believe that is a different
												level of leader who rolls up their sleeves and helps
												make it happen.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">Bonnie McCord</div>
											<div className="profile-org">
												Department of Health and Human Services, Tobacco Free
												Nebraska Program
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												My official title is Tobacco Prevention Educator. My
												primary responsibilities fall within the areas of
												Surveillance and Evaluation and Implementation,
												Administration, and Management of the CDC Grant
												DP20-2001.
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>Team-building</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>Delegation</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>
												“I will be a hummingbird. I will do the best that I
												can.” From “
												<a
													href="https://www.youtube.com/watch?v=IGMW6YWjMxw"
													target="_BLANK"
												>
													I will be a hummingbird
												</a>
												” - Wangari Maathai{" "}
											</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												Thomas E. Novotny, MD, MPH, for his broad-based work in
												tobacco control, especially regarding the impact of
												tobacco on the environment.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">Robert Ostbye</div>
											<div className="profile-org">
												Florida Department of Health, Bureau of Tobacco Free
												Florida
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												FI serve as a Statewide Policy Manager for the Bureau of
												Tobacco Free Florida. My primary responsibility in this
												role is managing specific policy initiatives.
												Specifically, directing the work local tobacco control
												programs do at the county level in pursuit of tobacco
												free school policies.
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>
												In my opinion, my most valuable leadership strength is
												adapting and applying innovative approaches to
												accomplish goals.
											</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>
												The leadership skills I most hope to strengthen by
												participating in the L&SS are my capacity for
												facilitating positive organizational/team culture and
												getting a better grasp on systems thinking so I can more
												strategically (and effectively) plan for success.
											</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>
												“The challenge of leadership is to be strong, but not
												rude; be kind, but not weak; be bold, but not bully; be
												thoughtful, but not lazy; be humble, but not timid; be
												proud, but not arrogant; have humor, but without folly.”
												- Jim Rohn
											</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												I have at least 3 answers to this question. I’ve worked
												for the Bureau of Tobacco Free Florida in varying
												capacities for 10 years. Throughout that time, Laura
												Corbin (our Bureau Chief) and Ron Davis (our Policy
												Section Lead and my direct supervisor) are the people I
												look up to the most. They have both been with the
												program for more than 20 years, they have always
												demonstrated an unwavering commitment to our mission and
												the mission of tobacco prevention and control in
												general, and they serve as a source of inspiration for
												our program. If it weren’t for Andrew Romero (Director
												of CADCA’s Geographic Health Equity Alliance), I may
												never have discovered a passion for this work. My first
												internship in college was spent working with his local
												tobacco prevention program in 2012. Ever since then, he
												has been an invaluable mentor and friend. His ingenuity,
												tireless commitment to the work, propensity for building
												effective teams, and the creativity he brings to
												addressing major challenges in tobacco control are just
												a few of the examples I try to follow in my own career.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">Sally Petty</div>
											<div className="profile-org">
												Indiana Department of Health’s (IDOH) Tobacco Prevention
												and Cessation division (TPC)
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												I am one of three Regional Program Directors within TPC.
												In this role, I manage 14 local community partnership
												grants covering 16 counties in southern Indiana. My
												primary responsibility is to ensure these partners
												receive training and technical assistance to implement
												evidence-based tobacco control interventions in their
												communities. I also serve on teams to support statewide
												and local smoke-free air policy efforts and increase
												utilization of Quit Now Indiana, and I contribute to
												other agency initiatives such as VOICE (statewide
												initiative to engage, educate and empower youth to take
												action against tobacco industry) and IDOH Health Equity
												Council.{" "}
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>
												My most valuable leadership strength is that am
												results-oriented, and I relish finding the way through a
												challenge. I enjoy keeping a team focused on the Vision
												and helping them plan and implement incremental steps to
												achieve their goals.{" "}
											</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>
												I want to be the kind of leader who empowers and
												supports people to work together to achieve big goals
												that matter to them. I especially want to improve my
												ability to empower those most impacted by the commercial
												tobacco industry and the decisions of our agency to have
												a voice in those decisions. I also want to support our
												local funded TPC coalitions and statewide partners to
												grow in their understanding and ability to apply equity
												and collaborative leadership principles in their work.{" "}
											</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>
												“The purpose of life is not to be happy. It is to be
												useful, to be honorable, to be compassionate, to have it
												make some difference that you have lived and lived
												well.” - Ralph Waldo Emerson
											</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												I admire former IDOH Chief of Staff Shane Hatchett. He
												was the driving force behind the Governor’s Public
												Health Commission that resulted in a significant
												expansion of Indiana’s public health programs this year.
												He worked behind the scenes to empower his team to
												achieve a major public health win. I think very few
												people knew about his role in this work until state
												leadership publicly recognized him.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">Melanie L. Ramson</div>
											<div className="profile-org">
												{" "}
												Louisiana Department of Health, Office of Public Health,
												Bureau of Chronic Disease Prevention & Healthcare
												Access’ Well-Ahead Tobacco Prevention and Control
												Manager
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												Responsible for the management and monitoring of
												Louisiana’s Tobacco Control grant performance measures
												and the Louisiana Tobacco strategic plan activities’
												progress throughout each grant year.{" "}
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>
												My most valuable leadership strength is my ability to
												listen, access, and communicate situational need. I
												consider myself to be ‘solution-oriented’…it’s this
												skill that informs my service strategy.
											</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>
												I most hope to develop critical, programmatic
												over-arching (big picture) perception/thinking skills.
												It’s important to me that staff and those served get a
												sense of their importance and that they are valued.{" "}
											</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>
												"To understand and become adept at leadership, one must
												master service."
											</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												I have had the extraordinary opportunity to learn about
												Louisiana’s Tobacco Control Program from Taylor Reine,
												TCP’s former Program Manager (and the current Well-Ahead
												LA Division Manager). It is under the leadership of Mrs.
												Reine that Louisiana’s TCP has become the robust and
												comprehensive Tobacco prevention and control program it
												is today.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">Kellen Schalter</div>
											<div className="profile-org">
												Geographic Health Equity Alliance (GHEA) at CADCA
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												I am a senior manager at GHEA and in the Public Health
												Programs department at CADCA. I lead the development of
												communities of practice for NTCPs and NCCCPs that focus
												on implementing policy, systems, and environmental
												change strategies related to commercial tobacco and
												cancer control (e.g., smokefree strategies, retail
												strategies, and complete streets).{" "}
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>
												Problem-solving is my most valuable leadership strength.
												I am able to navigate unique challenges effectively and
												adapt programs we develop to the needs of the programs
												we work with.{" "}
											</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>
												The skill I hope to strengthen most is strategic
												planning so that I can anticipate opportunities and
												challenges proactively.{" "}
											</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>
												“I define a leader as anyone who takes responsibility
												for finding the potential in people and processes, and
												who has the courage to develop that potential”{" "}
											</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												One person I look up to in the current tobacco control
												movement is Sally Herndon, the NC tobacco control
												program manager. In the times I’ve gotten to work with
												and learn from Sally, I’ve been impacted by her breadth
												of knowledge, ability to pivot to solve problems and
												willingness to test creative solutions. I admire how as
												a leader she improves the quality of her program,
												invests in staff, and partners with peers in the
												national tobacco control program.{" "}
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">Mike Sheldon</div>
											<div className="profile-org">
												Minnesota Department of Health
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												I am the Cessation Communications Coordinator and am
												responsible for the promotion of our statewide cessation
												program, Quit Partner. My role includes developing media
												plans, event planning, public relations, coordinating
												with our marketing vendor and collaborating with
												partners, grantees and other organizations to promote
												the program.
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>
												The ability to bring disparate groups together to
												address problems is my most valuable leadership
												strength.
											</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>
												I hope to gain insight on developing innovative
												solutions to problems. I’m working to change my mindset
												of narrowly thinking “that’s the way we’ve done it in
												the past” and moving to new ideas.
											</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>
												"Never doubt that a small group of thoughtful, concerned
												citizens can change the world. Indeed it is the only
												thing that ever has." - Margaret Mead
											</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												I would be hard pressed to pick just one person I most
												look up to, so I’ll cheat a bit and say the Minnesotans
												for a Smoke-Free Generation coalition. The coalition has
												so many dedicated leaders who care passionately about
												the health of all Minnesotans and work tirelessly to
												eliminate the harm of commercial tobacco throughout
												Minnesota.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="all-person-container">
								<div className="person-container">
									<div className="picture-container"></div>
									<div className="profile-container">
										<div className="header-container">
											<div className="profile-name">Micah Zimmermaker</div>
											<div className="profile-org">
												Washington State Department of Health
											</div>
										</div>
										<div className="question-container">
											Primary Role and Responsibilities
										</div>
										<div className="answer-container">
											<p>
												I currently serve as the Youth Commercial Tobacco
												Prevention Coordinator with the Washington State
												Department of Health. In this role I provide planning,
												budgeting, and programmatic guidance to 9 regional youth
												commercial tobacco and cannabis prevention contractors
												across the state. I also sit as a steering committee
												member within the statewide WA Breathes tobacco
												prevention coalition. Leaning on previous experience, I
												am also a resource for smokefree housing questions the
												program receives from Washington state residents.
											</p>
										</div>
										<div className="question-container">
											Most Valuable Leadership Strength
										</div>
										<div className="answer-container">
											<p>
												I believe my strongest leadership skill to be
												adaptability, alongside positivity, patience, and the
												ability to create a culture of trust.
											</p>
										</div>
										<div className="question-container">
											Leadership Skill I Hope to Strengthen
										</div>
										<div className="answer-container">
											<p>
												I oftentimes find myself involved in efforts behind the
												scenes and believe the next step in my evolution as a
												leader involves more visibility and uplifting those
												around me in collective spaces.
											</p>
										</div>
										<div className="question-container">
											Favorite Leadership Quote
										</div>
										<div className="answer-container">
											<p>“Flexibility is the key to stability” - John Wooden</p>
										</div>
										<div className="question-container">
											Person(s) I Most Look Up to in the Current Tobacco Control
											Movement
										</div>

										<div className="answer-container">
											<p>
												Person in the current tobacco control movement that I
												most look up to: I admire Heidi Glesmann for her
												tremendous ability to rebuild, restructure, and navigate
												the many changes our state’s program has faced over the
												past 3 years. She has strengthened our relationships
												with internal Washington partners and made lasting
												connections that help shape our work nationally.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Row>
				</Container>
			</div>
		</div>
	);
}
