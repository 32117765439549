import React, { useEffect, useState } from "react";
import "../styles/mybutton.css";

export default function CourseOptionBox(props) {
	const [bgColor, setBgColor] = useState("blue");
	const [title, setTitle] = useState("");
	const [width, setWidth] = useState("300px");
	const [outerWidth, setOuterWidth] = useState("300px");
	const [indent, setIndent] = useState("0px");
	// const buttonStyle = {
	// 	width: width,
	// 	background: bgColor,
	// };
	const outerStyle = {
		width: outerWidth,
		paddingLeft: indent,
	};
	useEffect(() => {
		let tBgColor;
		setTitle(props.title);
		// console.log(props.section);
		switch (props.section) {
			case 1:
				setBgColor("rgb(7, 71, 176)");
				break;
			case 2:
				setBgColor("rgb(7, 156, 176)");
				break;
			case 3:
				setBgColor("rgb(7, 176, 63)");
				break;
			case 4:
				setBgColor("rgb(176, 170, 7)");
				break;
			case 5:
				setBgColor("rgb(176, 72, 7)");
				break;
			case 6:
				setBgColor("rgb(176, 7, 7)");
				break;
			case 7:
				setBgColor("rgb(176, 7, 103)");
				break;
			case 8:
				setBgColor("rgb(156, 7, 176)");
				break;
		}

		// props.active?setWidth("300px"):setWidth("280px")
		// props.indent?setIndent("10px"):setIndent("0px")
		if (props.indent) {
			// console.log(props.indent);
			setIndent("10px");
			props.active ? setWidth("290px") : setWidth("270px");
		} else {
			setIndent("0px");
			props.active ? setWidth("300px") : setWidth("280px");
		}
	}, []);
	useEffect(() => {
		let tBgColor;
		switch (props.section) {
			case 1:
				setBgColor("rgb(7, 71, 176)");
				break;
			case 2:
				setBgColor("rgb(7, 156, 176)");
				break;
			case 3:
				setBgColor("rgb(7, 176, 63)");
				break;
			case 4:
				setBgColor("rgb(176, 170, 7)");
				break;
			case 5:
				setBgColor("rgb(176, 72, 7)");
				break;
			case 6:
				setBgColor("rgb(176, 7, 7)");
				break;
			case 7:
				setBgColor("rgb(176, 7, 103)");
				break;
			case 8:
				setBgColor("rgb(156, 7, 176)");
				break;
		}
		// props.active?setWidth("300px"):setWidth("280px")
		if (props.indent) {
			setIndent("20px");
			// props.active ? console.log(props.title) : setWidth("260px");
			props.active ? setWidth("280px") : setWidth("260px");
			// console.log(props.title);
		} else {
			setIndent("0px");
			props.active ? setWidth("300px") : setWidth("280px");
			// props.active ? console.log(props.title) : setWidth("280px");
			// console.log(props.title);
		}
	}, [props.active]);
	return (
		<div style={outerStyle}>
			<div
				className="course-option-box"
				style={{ width: width, background: bgColor }}
				onClick={() => props.onClick()}
			>
				{title}
			</div>
		</div>
	);
}
