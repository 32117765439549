import React from "react";
import { Button } from "react-bootstrap";
import { Form, Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useContext } from "react";
import { useEffect } from "react";
import MMlogo from "../img/mmlogo.png";
import AuthContext from "../context/authprovider";
import MyButton from "./mybutton";

export default function MentholMeeting(props) {
	const { auth, setAuth } = useContext(AuthContext);
	const [questions, setQuestions] = useState([]);
	const [check, setCheck] = useState(false);
	const [message, setMessage] = useState("");
	// const clickHandler = () => {
	// 	window.open(
	// 		"https://us02web.zoom.us/meeting/register/tZAvd-CurTkqGNzzMsSRh_-jWm0L-9SvLCqz",
	// 		"_blank"
	// 	);
	// };
	// const clickHandlerAgenda = () => {
	// 	window.open(
	// 		"https://www.tctcollab.com/assets/nn/2023%20Mining%20Lessons%20Learned%20Agenda.pdf",
	// 		"_blank"
	// 	);
	// };
	const [questionArray, setQuestionArray] = useState({
		q4: "",
		q6: "",
		q12: "",
		q13: "",
		q14: "",
		q15: "",
		q16: "",
		q17: "",
		q19: "",
		q20: "",
	});
	const confirmationStyle = {
		color: "darkblue",
		fontSize: "20px",
		paddingLeft: "200px",
		paddingRight: "200px",
	};
	const clickHandler = () => {
		let data = {
			userid: -1,
			eventid: 59,
			q4: questionArray["q4"],
			q6: questionArray["q6"],
			q12: questionArray["q12"],
			q13: questionArray["q13"],
			q14: questionArray["q14"],
			q15: questionArray["q15"],
			q16: questionArray["q16"],
			q17: questionArray["q17"],
			q19: questionArray["q19"],
			q20: questionArray["q20"],

			eventname: "2025 Menthol Recipient Meeting",
		};
		fetch(`https://www.api.tctcollab.com/event/mm/register`, {
			// fetch(`http://localhost:3001/event/mm/register`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((data) => data.json())
			.then((data) => setMessage(data));
		// console.log(data);
	};
	useEffect(() => {
		fetch(`https://www.api.tctcollab.com/event/register/questions/59`, {
			// fetch(`http://localhost:3001/event/register/questions/59`, {
			headers: {
				"Cache-Control": "no-cache",
			},
		})
			.then((data) => data.json())
			.then((data) => data.sort((a, b) => a.sort - b.sort))
			.then((data) => setQuestions(data));
		fetch(`https://www.api.tctcollab.com/tracking/sitevisit`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ page: "Menthol Meeting" }),
		});
	}, []);
	const handleForm = (event) => {
		let temp = questionArray;
		temp[event.target.getAttribute("question")] = event.target.value;
		setQuestionArray(temp);
		// console.log(temp);
		if (
			temp.q6 !== "" &&
			temp.q12 !== "" &&
			temp.q13 !== "" &&
			temp.q14 !== "" &&
			temp.q15 !== "" &&
			temp.q16 !== "" &&
			temp.q17 !== "" &&
			temp.q19 !== "" &&
			temp.q20 !== ""
		) {
			setCheck(true);
		} else setCheck(false);
		// console.log(questionArray);
	};
	const handleFormCheck = (event) => {
		let temp = questionArray;
		// console.log(event.target);
		temp[event.target.getAttribute("question")] =
			event.target.getAttribute("answer");
		setQuestionArray(temp);
		// console.log(temp);
		if (
			temp.q6 !== "" &&
			temp.q12 !== "" &&
			temp.q13 !== "" &&
			temp.q14 !== "" &&
			temp.q15 !== "" &&
			temp.q16 !== "" &&
			temp.q17 !== "" &&
			temp.q19 !== "" &&
			temp.q20 !== ""
		) {
			setCheck(true);
		} else setCheck(false);
		// console.log(questionArray);
	};
	return (
		<div className="nn-container am-container">
			<Container>
				<div className="info-container">
					<Row>
						<Col>
						<div style={{color:"white",fontFamily:"arial",fontSize:"40px", width:"100%", backgroundColor:"darkred", padding:"10px",fontWeight:"Bold"}}>
							Effective 1-22-2025: This meeting has been cancelled.
						</div>
							<img src={MMlogo} rel="2025 Menthol Recepient Meeting" />
							<h2>
								Reducing the Burden of Menthol and Other Flavored Commercial
								Tobacco Products in Communities that Experience Health
								Disparities:
								<br />
								<em>
									Celebrating Year One of CDC-RFA-DP-23-0019 and Preparing for
									Year Two
								</em>
							</h2>
							{/* <h1>Mining Lessons Learned and their Impact on the Future</h1> */}
							{/* <p className="sub-header">
								2023 Meeting of National Networks and CDC's Office on Smoking
								and Health and Division of Cancer Prevention and Control
							</p> */}
							<p
								style={{
									fontWeight: "Bold",
									fontSize: "50px",
									textAlign: "center",
									color: "rgb(43, 173, 126)",
								}}
							>
								January 23 - 24, 2025 | 1.5 Days
							</p>
							<div style={{fontSize: "25px",width:"85%",textAlign:"justify",textJustify:"inter-word", margin:"auto",backgroundColor:"rgb(255, 248, 194)",padding:"15px"}}>
								<p style={{textAlign:"justify",textJustify:"inter-word"}}>
								Dear 2025 Menthol Recipient Meeting Attendees and Speakers,
								</p>
								<p style={{textAlign:"justify",textJustify:"inter-word"}}>
								Good afternoon from icy Atlanta.  I am writing to inform you that, due to the severe weather state of emergency currently affecting Dekalb County, we have made the difficult decision to cancel this week’s <em>2025 Menthol Award Recipient Meeting</em>.
								</p>
								<p style={{textAlign:"justify",textJustify:"inter-word"}}>
								We sincerely apologize for any inconvenience this may cause, especially for those who have already traveled to attend. Your safety and well-being are our top priorities, and we believe this is the best course of action given the circumstances.
								</p>
								<p style={{textAlign:"justify",textJustify:"inter-word"}}>
								We truly value your partnership and appreciate your understanding during this time. We will be in touch soon with updates regarding rescheduling the meeting.
								</p>
								<p>
								Thank you for your patience and support.
								</p>
								<p style={{textAlign:"justify",textJustify:"inter-word"}}>Warm regards,</p><p>Karla S. Sneegas, MPH<br />Chief<br/>OSH Program Services Branch</p>
							</div>
							{/* <h3 style={{ color: "red" }}> */}
								{/* Registration Closes Monday, January 6, 2025. */}
								{/* Registration is now closed.
							</h3> */}
							<hr />
							{/* <div className="header-text">Conference Venue</div>
							<p style={{ textAlign: "justify", textAlign: "center" }}>
								<strong style={{ fontWeight: "Bold", fontSize: "20px" }}>
									CDC Global Communications Center (GCC B19)
								</strong>
								<p
									style={{
										textAlign: "center",
									}}
								>
									1600 Clifton Rd
									<br />
									Atlanta, GA 30329
								</p>
								<p
									style={{
										textAlign: "center",
									}}
								>
									{/* <strong>Phone:</strong> (770) 394-6500 */}
								{/* </p>  */}
								{/* <div className="header-text">Lodging</div>
								<p style={{ textAlign: "justify", textAlign: "center" }}>
									<strong style={{ fontWeight: "Bold", fontSize: "20px" }}>
										Emory Conference Center Hotel
									</strong>
									<p
										style={{
											textAlign: "center",
										}}
									>
										1615 Clifton Rd, NE
										<br />
										Atlanta, GA 30329
									</p>
									<p
										style={{
											textAlign: "center",
										}}
									>
										<strong>Phone:</strong> (404) 712-6000
									</p>
								</p> */}
								{/* <p
									style={{
										textAlign: "center",
									}}
								>
									<span style={{ fontWeight: "Bold", fontSize: "20px" }}>
										Last Day to Book:
									</span>
									<br />
									Tuesday, December 31, 2024
								</p>
								<p
									style={{
										textAlign: "center",
									}}
								>
									<span style={{ fontWeight: "Bold", fontSize: "20px" }}>
										Booking
									</span>
									<br />
									<a href="https://book.passkey.com/e/50892582" target="_blank">
										Click here
									</a>{" "}
									to book your rooms now through December 31, 2024 at the
									prevailing January 2025 GSA rate.
									<br />
									<strong>Please Note:</strong> The January 2025 GSA lodging
									rate has been published. It is $197/night. The Per Diem rates
									are published{" "}
									<a
										href="https://www.gsa.gov/travel/plan-book/per-diem-rates/per-diem-rates-results?action=perdiems_report&fiscal_year=2025&state=GA&city=&zip="
										target="_blank"
									>
										here
									</a>
									.
								</p> */}
							{/* </p> */}
							{/* <div className="header-text">Meeting Agenda</div> */}
							{/* <p style={{ fontSize: "20px", textAlign: "center" }}>
								<strong>Full Agenda Coming Soon!</strong>
								<h5>
									<strong>
										Download Whova to access the most current agenda.
									</strong>
								</h5>
								<div>
									<Button
										href="https://play.google.com/store/apps/details?id=com.whova.event&amp;referrer=utm_source%3Ddownload_widget%26utm_medium%3Dwidget%26utm_content%3Dcdcco_202403"
										target="_blank"
									>
										Android App
									</Button>
								</div>
								<div>
									<Button
										href="https://itunes.apple.com/app/apple-store/id716979741?pt=1944835&amp;ct=download_widget&amp;mt=8"
										target="_blank"
									>
										iOS App
									</Button>
								</div> */}
							{/* <p>
								<p style={{ textAlign: "center" }}>
									<a
										href="https://tctcollab.com/assets/pdf/mm/agenda.pdf"
										target="_blank"
									>
										New Condensed Agenda
									</a>{" "}
									<em>(eff. 1-21-2025)</em>
								</p>
							</p> */}
							{/* <div className="header-text">Travel Considerations</div>
							<h5>Skill-Building Workshop</h5>
							<p>
								TCTC will host an{" "}
								<strong>
									<em>
										in-person Skill-Building Workshop for New Program Managers
									</em>
								</strong>{" "}
								on <strong>Monday, March 4th from 1:00 - 5:00 PM EST</strong>.
								If you plan to register for this workshop, please plan your
								travel accordingly.
							</p>
							<p>
								This registration is seperate from the Awardee Meeting
								Registration. You can access it by clicking the{" "}
								<strong>SBW</strong> tab at the top of the page.
							</p>
							{/* <p>
								Registration for this workshop is now open{" "}
								<a href="https://www.tctcollab.com/sbw">here</a>.
							</p> 
							<p>
								Please find the SBW agenda for travel authorization{" "}
								<a
									href="https://www.tctcollab.com/assets/am23/sbw/2023%20TCTC%20Skillbuilding%20Workshop%20New%20PM%20Agenda_TrvlAuth-v1.pdf"
									target="_BLANK"
								>
									here
								</a>
								.
							</p>{" "}
							*/}
							{/* <div className="header-text">Lunch</div>
							<div style={{paddingBottom:"15px"}}>Lunches ordered for Wednesday, the 22nd, will be refunded by the vendor.  Lunches ordered for Thursday will be delivered, as scheduled.
							</div> */}
							{/* <p>
								Although lunch will be the attendees' responsibility, as a
								convenience, TCTC is collaborating with Jason's Deli so
								participants/attendees who wish to order their lunches ahead of
								time can do so. This will allow for the convenience of staying
								at the meeting location to enjoy meals with peers and
								colleagues, as exiting the premises to pick up lunch will
								require some planning and time management (e.g., transportation,
								reentrance security check, etc.). Following are important
								details to note for those who would like to take advantage of
								the option to order your lunches, in advance of the meeting.
							</p>
							<p>
								<strong>Order Link:</strong>{" "}
								<a
									href="https://events.jasonsdeli.com/app/events/J7KYDVU3HE"
									target="_BLANK"
								>
									Click here
								</a>{" "}
								to order your lunches.
							</p>
							<p>
								<strong>Order Deadline:</strong> January 19, 2025 at 11:59 PM ET
							</p>
							<p>
								<strong>Dates:</strong> The order option is available for Days 1
								and 2 of the meeting (January 22 & 23). Purchases can be
								completed for one or both full meeting days via one transaction.
								Once purchases are completed, a copy of the order/receipt will
								be made available for your records.
							</p>
							<p>
								<strong>Options:</strong> Boxed lunch options are listed below.{" "}
								<ul>
									<li>
										KP Nutty Mixed Up Salad Box (the cranberry/walnut mix will
										be packaged on the side)
									</li>
									<li>
										KP Chicken Club Salad Box (can be fully converted to a
										vegetarian salad by removing the chicken and bacon, upon
										request)
									</li>
									<li>KP Boardroom Box</li>
									<li>KP Glueten Sensitive Meal</li>
								</ul>
								<em>
									Each meal includes a bottled water, chips, a pickle, and a
									choice of a Cranberry Walnut Cookie, Chocolate Chip Cookie, or
									Fudge-Nut Brownie. All meals are priced under the GSA per diem
									rate.
								</em>
							</p>
							<p>
								Please visit the order link to see the details of each option
								provided.
							</p>
							<p>
								<strong>Order Customization:</strong> All boxed lunch options
								come as-is, unless otherwise noted, above. Please contact TCTC
								Support at{" "}
								<a href="mailTo:support@tctcollab.com">support@tctcollab.com</a>{" "}
								if you require customization of the KP Chicken Club Salad Box to
								remove the chicken and bacon, only.
							</p>
							<p>
								<strong>Questions:</strong> Please direct all lunch-related
								questions to{" "}
								<a href="mailTo:support@tctcollab.com">support@tctcollab.com</a>{" "}
								with the SUBJECT LINE: Menthol Meeting Lunch.
							</p>
							<div style={{ width: "200px", margin: "auto" }}>
								<a
									href="https://events.jasonsdeli.com/app/events/J7KYDVU3HE"
									target="_BLANK"
								>
									<Button>Order Here</Button>
								</a>
							</div> */}
							{/* <p>
								{/* <strong>Full Agenda Coming Soon!</strong> 
								TCTC has collaborated with the hotel to manage the lunch order
								and distribution processes. The process is cashless and will
								permit meeting registrants/faculty who will be staying at the
								hotel to have their meals charged to their rooms at the
								prevailing GSA per diem rate. For those who will be traveling in
								locally, meals reserved will be paid for on-site at a POS
								payment station–accepting credit and debit card payments only.
							</p>
							<p>
								TCTC will use the pre-order lunch list on Days 1 and 2 of the
								meeting to direct:
								<ul>
									<li>
										<strong>participants who are staying at the hotel</strong>{" "}
										with pre-paid lunch tickets to the station associated with
										their meal selection. There, they will exchange their ticket
										for the appropriate boxed lunch.
									</li>
									<li>
										<strong>
											local meeting attendees who are not staying at the hotel
										</strong>{" "}
										to a POS payment station for check-out ahead of collecting
										their paid lunch tickets. Once they have their tickets,
										these attendees will navigate to the appropriate lunch
										station to obtain their meal selection.
									</li>
								</ul>
							</p>
							<p>
								The lunch choices are as follows and each include whole fruit, a
								bag of chips, cookie, and a bottled water:
								<ol>
									<li>Turkey Sandwich on Multi Grain</li>
									<li>Ham Sandwich on Brioche Roll</li>
									<li>Grilled Vegetable and Hummus Wrap</li>
									<li>
										Salad with Mixed Greens, Tomatoes, Cucumbers, and Carrots
									</li>
								</ol>
							</p>
							<p style={{ color: "rgb(173, 23, 0)" }}>
								<strong>
									PLEASE NOTE: BY SELECTING A LUNCH OPTION DURING REGISTRATION,
									YOU ARE COMMITING TO THE PURCHASE OF THAT CHOICE.
								</strong>
							</p> */}
							{/* <div className="header-text">Registration</div>
							<p>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "20px",
										textAlign: "left",
									}}
								>
									Allowed Participants
								</span>
								<ul>
									<li>
										CDC-RFA-DP-23-0019 recipients and their community partners
										(up to 7 people per recipient);
									</li>

									<li>
										Representatives from national partner organizations; and
									</li>
									<li>CDC-OSH staff.</li>
								</ul>
							</p>
							<hr />
							<p>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "20px",
										textAlign: "left",
										marginBottom: "20px",
									}}
								>
									Fill out the information below to register
								</span>
							</p> */}
							{/* <p>
								{questions.map((question) => {
									let tempType = "text";
									if (question.type == 0) {
										tempType = "text";
										return (
											<Form.Group
												key={question.questionid}
												className="mb-3"
												controlId={`form${question.questionid}`}
											>
												<Form.Label>{question.question}</Form.Label>
												<Form.Control
													type={tempType}
													// placeholder={question.question}
													question={`q${question.questionid}`}
													onChange={handleForm}
												/>
											</Form.Group>
										);
									} else if (question.type == 1) {
										tempType = "radio";
										return (
											<Form.Group
												key={question.questionid}
												className="mb-3"
												controlId={`form${question.questionid}`}
											>
												<Form.Label>{question.question}</Form.Label>
												{question.answers.map((answer) => {
													return (
														<Form.Check
															type={tempType}
															name={"group" + question.questionid}
															label={answer.choice_descr}
															placeholder={question.question}
															question={`q${question.questionid}`}
															answer={answer.choiceid}
															onChange={handleFormCheck}
														/>
													);
												})}
											</Form.Group>
										);
									} else if (question.type == 2) {
										tempType = "checkbox";
										return (
											<Form.Group
												key={question.questionid}
												className="mb-3"
												controlId={`form${question.questionid}`}
											>
												<Form.Label>{question.question}</Form.Label>
												{question.answers.map((answer) => {
													return (
														<Form.Check
															type={tempType}
															label={answer.choice_descr}
															placeholder={question.question}
															question={`q${question.questionid}`}
															answer={answer.choiceid}
															onChange={handleFormCheck}
														/>
													);
												})}
											</Form.Group>
										);
									}
								})}

								<div style={{ textAlign: "center" }}>
									{/* {JSON.stringify(auth) === "{}" ? (
										<Button variant="success" disabled>
											Please login to register for the Awardee Meeting.
										</Button>
									) : ( */}
									{/* <Button
										variant="success"
										onClick={clickHandler}
										disabled={!check}
									>
										Click Here to Submit Your Registration
									</Button>
									{/* )} */}
									{/* <p style={confirmationStyle}>
										<strong>{message !== "" && message}</strong>
									</p>
								</div>
							</p>  */}
							{/* <div className="header-text">Abstract Process</div>
							<p style={{ fontSize: "20px", textAlign: "center" }}>
								<strong>
									Abstract Submissions are Now Closed. The Review Process is
									Underway.
								</strong>
							</p> */}
							{/* <p></p> */}
							{/* <p>
								We are seeking abstracts showcasing the lessons learned, process
								and implementation highlights, challenges and breakthroughs,
								emerging issues and trends, and accomplishments during the
								implementation of the DP20-2001. We encourage and welcome
								proposals from CDC-OSH awardees across all states and islands.
							</p>
							<p>
								<strong>Start Here! </strong>
								<a href="https://forms.gle/ewrMACA1UrjQW8KB7" target="_blank">
									CLICK HERE
								</a>{" "}
								to begin the online abstract submission process.
							</p>
							<p>
								<strong>Abstract Guidelines One-Pager.</strong> The following
								document is a one-paged resource that answers questions you may
								have regarding the abstract submission process:{" "}
								<a
									href="https://www.tctcollab.com/assets/am23/2024%20State%20Showcase%20Abstracts%20Guidance_092823.pdf"
									target="_blank"
								>
									2024 State Showcase Abstracts Guidance
								</a>
							</p>
							<p>
								<strong>Submission Deadline.</strong> The deadline for abstract
								submissions has been extended to <strong>October 20th</strong>.
								It is our hope that this will provides a little more room to
								successfully submit an abstract.
							</p>
							<p>
								<strong>Microsoft Word Submission Form.</strong> For those who
								are unable to access the online submission form, please{" "}
								<a
									href="https://www.tctcollab.com/assets/am23/2024%20Abstract%20Submission%20Form-FNL.docx"
									target="_BLANK"
								>
									CLICK HERE
								</a>{" "}
								for a Word version of the submission form that can be completed
								and submitted via email to Bonita McGee (
								<a href="mailTo:brmcgee@catalystcf.com">
									brmcgee@catalystcf.com
								</a>
								) with the subject line: "--Organization Name-- Abstract
								Submission".
							</p>
							<p>
								<strong>Hybrid Option Update. </strong>We will not have a hybrid
								option for the upcoming awardee meeting. We encourage you to
								utilize alternate means of bringing voice to those who will not
								be physically present via video, Loom, or equivalent
								technologies, should travel not be an option.
							</p>
							<h4>Q&A</h4>
							<p>
								<strong>
									Is it possible to extend the abstract submission deadline past
									the current deadline of October 16, 2023?
								</strong>
							</p>
							<p>
								The deadline has been extended from the October 16th to October
								20th. This newly extended deadline takes into account the time
								required to review, select, inform, and prepare for the meeting
								beyond the submission close date. (PSB/TCTC)
							</p>
							<p>
								<strong>
									If a state program is unable to get travel approved to travel
									outside of the state in March 2024 to attend the Awardee
									Meeting, can they send their Local Lead Agency (LLA) - since
									the LLA would not have to have state approval to travel, if it
									comes down to that?
								</strong>
							</p>
							<p>
								We were not planning to include local staff or partners in this
								meeting, generally speaking. All of the sessions are being built
								with state programs in mind so LLAs would find it difficult to
								find relevant content for their needs. (PSB/TCTC)
							</p>
							<p>
								<strong>Are states limited to 3 abstracts? </strong>
							</p>
							<p>Yes. Only 3 submissions, max, are allowed. (TCTC)</p>
							<p>
								<strong>
									Do states (not the partners) submit the abstract?
								</strong>
							</p>
							<p>States are the lead and must submit the abstract. (TCTC)</p>
							<p>
								<strong>Still Have Questions? </strong>If you are still on the
								fence, don’t hesitate to contact your project officer, national
								network, or any other partner to consider how to represent your
								state’s contributions and achievements best.{" "}
							</p>
							<p>
								For additional questions about the abstract submission process
								not answered by the guidance document provided, please contact
								Bonita McGee (
								<a href="mailTo:brmcgee@catalystcf.com">
									brmcgee@catalystcf.com
								</a>
								).
							</p> */}
							<div className="header-text">Questions?</div>
							<p style={{ fontSize: "20px", textAlign: "center" }}>
								<strong>
									Contact us:{" "}
									<a href="mailTo:support@tctcollab.com">
										support@tctcollab.com
									</a>
								</strong>
							</p>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	);
}
