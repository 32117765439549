import React from "react";
import { Button } from "react-bootstrap";
import MyButton from "./mybutton";
import { Form, Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useContext, useEffect } from "react";
// import VCLogo from "../img/vclogo.png";
import AuthContext from "../context/authprovider";
export default function CoP(props) {
	let params = useParams();
	//test
	const { auth, setAuth } = useContext(AuthContext);
	const [npm, setNpm] = useState(true);
	const [yc, setYc] = useState(false);

	// const [password,setPassword]=useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [message, setMessage] = useState("");
	const style = {
		maxWidth: "800px",
		textAlign: "center",
		margin: "auto",
		boxShadow: "5px 5px 5px grey",
	};
	useEffect(() => {
		let saved = localStorage.getItem("user");
		if (saved && JSON.stringify(auth) === "{}") {
			setAuth(JSON.parse(saved));
		}
		// console.log(auth.userid);
	}, []);
	const npmhandler = () => {
		setNpm(true);
		setYc(false);
		setMessage("");
	};
	const ychandler = () => {
		setNpm(false);
		setYc(true);
		setMessage("");
	};
	const handleSubmit = () => {
		let vc = 60;
		let eventname = "Tools to Guide Coalition Development";
		// let cop = 0;
		// let eventname = "";
		// if (npm) {
		// 	cop = 33;
		// 	eventname =
		// 		"New Commercial Tobacco Control Program Manager Community of Practice";
		// } else {
		// 	cop = 34;
		// 	eventname = "State Youth Coordinator Community of Practice";
		// }
		let data = {
			eventid: vc,
			userid: auth.userid,
			eventname,
			meetingid: 81413322200,
		};
		fetch(`https://www.api.tctcollab.com/event/vc/register`, {
			// fetch(`http://LocalHost:3001/event/vc/register`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((data) => data.json())
			.then((data) => {
				setMessage(data);
			});
	};
	useEffect(() => {}, []);
	return (
		<div className="steps-container">
			<div style={{ marginBottom: "20px" }}>
				<Container>
					<Row>
						<div
							style={{
								width: "100%",
								textAlign: "center",
								marginBottom: "10px",
							}}
						>
							<img
								style={{ width: "50%", textAlign: "center" }}
								// src={CoPLogo}
							/>
							<h3 style={{ marginTop: "30px" }}>
								<span style={{ color: "green" }}>
									TCTC's 2024-2025 Video-Conference Series
								</span>
								<br />{" "}
								<em>
									Coalitions in Commercial Tobacco Control: Building Blocks for
									Success
								</em>
							</h3>
							{/* <div>
								<h3 style={{ marginTop: "30px" }}>Select a CoP Below</h3>
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "stretch",
									}}
								>
									<div style={{ width: "30%" }}>
										<MyButton
											onClick={() => npmhandler()}
											variant={npm ? "blue" : "grey"}
										>
											New CTC Program Managers
										</MyButton>
									</div>{" "}
									<div style={{ width: "30%" }}>
										<MyButton
											onClick={() => ychandler()}
											variant={yc ? "green" : "grey"}
										>
											State Youth Coordinators
										</MyButton>
									</div>
								</div>
								<h4 style={{ color: "red" }}>
									<em>Registration is Now Closed</em>
								</h4>
							</div> */}
						</div>
						{/* <h2 style={{ color: "blue", marginBottom: "30px" }}>
							{message !== "" && message}
						</h2> */}
					</Row>
					{/* <Row>
						<Col>
							<h3 style={{ marginBottom: "30px" }}>
								{{npm
									? "New Commercial Tobacco Control Program Manager Community of Practice Information"
									: "State Youth Coordinator Community of Practice Information"} }
								Policy and Strategy Thinking for State and Community
								Interventions
							</h3>
						</Col>
					</Row> */}

					<Row style={{}}>
						<div className="cop-container npm">
							<div style={{ textAlign: "justify", textJustify: "inter-word" }}>
								<p>
									TCTC's 2024-2025 Video-Conference Series,{" "}
									<strong>
										<em>
											Coalitions in Commercial Tobacco Control: Building Blocks
											for Success
										</em>
									</strong>
									, will highlight thought-provoking considerations of purpose,
									value, support and effectiveness of coalitions in guiding
									implementation of comprehensive commercial tobacco prevention
									and control plans.
								</p>
								<p>
									The primary audience for the series is state commercial
									tobacco control program managers and policy coordinators.
								</p>
							</div>
							{/* <div className="header-text">Target Members</div>
							<p style={{ marginBottom: "30px" }}>
								The primary audience for the series is state commercial tobacco
								control program managers and policy coordinators.
							</p> */}

							<div className="header-text">
								Video-conference #3: Tools to Guide Coalition Development
							</div>
							<p
								style={{
									marginBottom: "30px",
									textAlign: "center",
									fontWeight: "bold",
								}}
							>
								January 30, 2025 | 3:00-4:30 PM ET{" "}
							</p>
							<div className="vcflex-container">
								<div className="vcitem">
									<div className="vcsub-header-text">Objectives</div>
									<p>
										By the close of this video-conference, participants will be
										able to utilize a series of tools for coalition development
										(coalition start up; coalition planning; coalition building
										and coalition sustainability).
									</p>
									<div className="vcsub-header-text">Speakers</div>
									<p>
										<strong>Bonita McGee</strong> Organizational Development
										Consultant, IRC’s Tobacco Control Training Collaborative
									</p>
								</div>
								<div className="vcitem">
									<div className="vcsub-header-text">Registration</div>
									<p>
										Registration for this video-conference will remain open
										until Monday, January 27th. Once registered, you will
										receive TWO emails:
										<ul>
											<li>An email confirmation from support@tctcollab.com</li>
											<li>An email containing the Zoom Tag from Zoom.us</li>
										</ul>{" "}
										If you do not receive these emails, please reach out to{" "}
										<a href="mailTo:support@tctcollab.com">
											support@tctcollab.com
										</a>
										.
									</p>
									{JSON.stringify(auth) === "{}" ? (
										<Button variant="dark" disabled>
											Please login to register for this Video-Conference
										</Button>
									) : (
										<Button variant="primary" onClick={() => handleSubmit()}>
											Click Here to Register for VC#3 Now
										</Button>
									)}
									{/* <p
										style={{
											color: "red",
											fontWeight: "bold",
											textAlign: "center",
										}}
									>
										Registration is now closed.
									</p> */}
								</div>
							</div>
							<hr />
							<Row>
								<h2 style={{ color: "blue" }}>{message !== "" && message}</h2>
							</Row>
							<hr />

							<div className="header-text">
								Video-conference #2: Coalition Infrastructure and Operations
							</div>
							<p
								style={{
									marginBottom: "30px",
									textAlign: "center",
									fontWeight: "bold",
								}}
							>
								November 21, 2024 | 3:00-4:30 PM ET{" "}
							</p>
							<div className="vcflex-container">
								<div className="vcitem">
									<div className="vcsub-header-text">Objectives</div>
									<p>
										By the close of this video-conference, participants will be
										able to:
										<ul>
											<li>
												Identify eight questions to ask before a coalition is
												formed;
											</li>
											<li>
												Identify essential infrastructure and operational
												elements for coalitions; and
											</li>
											<li>
												Discuss how principles of equity are applied in
												coalition operations.{" "}
											</li>
										</ul>
									</p>
									<div className="vcsub-header-text">Speakers</div>
									<p>
										<strong>Molly Moilanen</strong> former MN statewide
										coalition chair; consultant to CDC-OSH
										<br />
										<strong>Janelle Waldock</strong> Director of Racial & Health
										Equity Policy, Blue Cross and Blue Shield of Minnesota{" "}
										<br />
										<strong>Gene Nichols</strong> Community Advocate, Minnesota
										<br />
										<strong>Minou Jones</strong> Founder and CEO, Making it
										Count Community Development Corporation
									</p>
								</div>
								<div className="vcitem">
									<div className="vcsub-header-text">Registration</div>
									<p>
										Registration for this video-conference will remain open
										until Monday, November 18th. Once registered, you will
										receive TWO emails:
										<ul>
											<li>An email confirmation from support@tctcollab.com</li>
											<li>An email containing the Zoom Tag from Zoom.us</li>
										</ul>{" "}
										If you do not receive these emails, please reach out to{" "}
										<a href="mailTo:support@tctcollab.com">
											support@tctcollab.com
										</a>
										.
									</p>
									{/* {JSON.stringify(auth) === "{}" ? (
										<Button variant="dark" disabled>
											Please login to register for this Video-Conference
										</Button>
									) : (
										<Button variant="primary" onClick={() => handleSubmit()}>
											Click Here to Register for VC#2 Now
										</Button>
									)} */}
									<p
										style={{
											color: "red",
											fontWeight: "bold",
											textAlign: "center",
										}}
									>
										Registration is now closed.
									</p>
								</div>
							</div>
							<hr />
							<Row>
								<h2 style={{ color: "blue" }}>{message !== "" && message}</h2>
							</Row>
							<hr />
							<div className="header-text">
								Video-conference #1: Why Coalitions? A Primer{" "}
							</div>
							<p
								style={{
									marginBottom: "30px",
									textAlign: "center",
									fontWeight: "bold",
								}}
							>
								October 24, 2024 | 3:00-4:30 PM ET{" "}
							</p>
							<div className="vcflex-container">
								<div className="vcitem">
									<div className="vcsub-header-text">Objectives</div>
									<p>
										By the close of this video-conference, participants will be
										able to:
										<ul>
											<li>
												Define the value and purpose of coalitions in commercial
												tobacco control;
											</li>
											<li>
												Discuss the role of state programs in local and
												statewide coalitions; and
											</li>
											<li>Explain lobbying restrictions on federal funding.</li>
										</ul>
									</p>
									<div className="vcsub-header-text">Speakers</div>
									<p>
										<strong>Molly Moilanen</strong> former MN statewide
										coalition chair; consultant to CDC-OSH
										<br />
										<strong>Michah Zimmermaker</strong> Youth Commercial Tobacco
										Prevention Coordinator; Youth Cannabis & Commercial Tobacco
										Prevention Program, Washington State Department of Health{" "}
										<br />
										<strong>Jade Chambers-Blair</strong> Public Health Analyst,
										CDC Office on Smoking and Health
									</p>
								</div>
								<div className="vcitem">
									<div className="vcsub-header-text">Registration</div>
									<p>
										Registration for this video-conference will remain open
										until Monday, October 21st. Once registered, you will
										receive TWO emails:
										<ul>
											<li>An email confirmation from support@tctcollab.com</li>
											<li>An email containing the Zoom Tag from Zoom.us</li>
										</ul>{" "}
										If you do not receive these emails, please reach out to{" "}
										<a href="mailTo:support@tctcollab.com">
											support@tctcollab.com
										</a>
										.
									</p>
									{/* {JSON.stringify(auth) === "{}" ? (
										<Button variant="dark" disabled>
											Please login to register for this CoP
										</Button>
									) : (
										<Button variant="primary" onClick={() => handleSubmit()}>
											Click Here to Register for VC#1 Now
										</Button>
									)} */}
									<p
										style={{
											color: "red",
											fontWeight: "bold",
											textAlign: "center",
										}}
									>
										Registration is now closed.
									</p>
								</div>
							</div>

							<hr />

							<p>
								Registration will open for{" "}
								<strong>
									<em>
										Video Conference #4: Assessing Coalition Outcomes and Impact
									</em>
								</strong>{" "}
								on Monday, January 20th.
							</p>
						</div>

						{/* {JSON.stringify(auth) === "{}" ? (
									<Button variant="success" disabled>
										Please login to register for this CoP
									</Button>
								) : (
									<Button variant="success" onClick={() => handleSubmit()}>
										Register for the State Youth Coordinator CoP by Clicking
										Here
									</Button>
								)} */}
						{/* </div>
						)} */}
					</Row>

					<hr />
				</Container>
			</div>
		</div>
	);
}
