import React from "react";
import { Button } from "react-bootstrap";
import { Form, Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useContext } from "react";
import { useEffect } from "react";
import COPlogo from "../img/coplogo.png";
import AuthContext from "../context/authprovider";

export default function NewPmCop(props) {
	const { auth, setAuth } = useContext(AuthContext);
	const [questions, setQuestions] = useState([]);
	const [check, setCheck] = useState(false);
	const [message, setMessage] = useState("");
	// const clickHandler = () => {
	// 	window.open(
	// 		"https://us02web.zoom.us/meeting/register/tZAvd-CurTkqGNzzMsSRh_-jWm0L-9SvLCqz",
	// 		"_blank"
	// 	);
	// };
	// const clickHandlerAgenda = () => {
	// 	window.open(
	// 		"https://www.tctcollab.com/assets/nn/2023%20Mining%20Lessons%20Learned%20Agenda.pdf",
	// 		"_blank"
	// 	);
	// };
	const [questionArray, setQuestionArray] = useState({
		q10: "",
		q11: "",
	});
	const confirmationStyle = {
		color: "darkblue",
		fontSize: "20px",
		paddingLeft: "200px",
		paddingRight: "200px",
	};
	const clickHandler = () => {
		let data = {
			userid: auth.userid,
			eventid: 57,
			q10: questionArray["q10"],
			q11: questionArray["q11"],
			eventname:
				"New Commercial Tobacco Control Program Managers Community of Practice",
		};
		fetch(`https://www.api.tctcollab.com/event/npmcop/register`, {
			// fetch(`http://localhost:3001/event/am/register`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((data) => data.json())
			.then((data) => setMessage(data));
		// console.log(data);
	};
	useEffect(() => {
		fetch(`https://www.api.tctcollab.com/event/register/questions/57`, {
			headers: {
				"Cache-Control": "no-cache",
			},
		})
			.then((data) => data.json())
			.then((data) => setQuestions(data));
		fetch(`https://www.api.tctcollab.com/tracking/sitevisit`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ page: "New PM CoP" }),
		});
	}, []);
	const handleForm = (event) => {
		let temp = questionArray;
		temp[event.target.getAttribute("question")] = event.target.value;
		setQuestionArray(temp);
		// console.log(questionArray);
	};
	const handleFormCheck = (event) => {
		let temp = questionArray;
		// console.log(event.target);
		temp[event.target.getAttribute("question")] =
			event.target.getAttribute("answer");
		setQuestionArray(temp);
		if (temp.q10 != "" && temp.q11 != "") {
			setCheck(true);
		}
		// console.log(questionArray);
	};
	return (
		<div className="nn-container am-container">
			<Container>
				<div className="info-container">
					<Row>
						<Col>
							<img src={COPlogo} rel="Community of Practice" />
							<h2>
								New Commercial Tobacco Control Program Managers
								<br />
								Community of Practice
							</h2>
							{/* <h1>Mining Lessons Learned and their Impact on the Future</h1> */}
							{/* <p className="sub-header">
								2023 Meeting of National Networks and CDC's Office on Smoking
								and Health and Division of Cancer Prevention and Control
							</p> */}
							<div className="header-text">Meeting Dates</div>
							<p
								style={{
									fontWeight: "Bold",

									textAlign: "center",
								}}
							>
								All sessions will be held between 1:00 PM ET and 2:30 PM ET on
								the following dates: <br />
								Wednesday, January 15, 2025
								<br />
								Wednesday, January 29, 2025
								<br />
								Wednesday, February 12, 2025
								<br />
								Wednesday, February 26, 2025
							</p>
							{/* <h3 style={{ color: "red" }}>Registration is Now Closed</h3> */}
							<hr />

							<div className="header-text">Meeting Objectives</div>

							<p
								style={{
									fontSize: "20px",
									textAlign: "justify",
									textJustify: "interword",
								}}
							>
								{/* <strong>Full Agenda Coming Soon!</strong> */}
								This CoP will focus on new (less than 2 years) commercial
								tobacco control program managers who are interested in
								increasing their leadership skills and tactical strategies for
								the management of comprehensive programs. Meeting together with
								other new commercial tobacco control program managers in the
								spirit of learning, knowledge sharing, and collaboration is the
								heart of this CoP. The objectives of the four video-conference
								sessions will be to:
								<ul>
									<li>
										Construct foundation for positive mindset and leadership
										development of new leaders in the National Tobacco Control
										Movement;{" "}
									</li>
									<li>
										Develop knowledge of essential commercial tobacco control
										resources and how to seek support and technical assistance
										from national partners;{" "}
									</li>
									<li>
										Identify strategies for building a comprehensive staffing
										model for successful implementation of commercial tobacco
										control intervention in an ever-changing landscape;{" "}
									</li>
									<li>
										Create opportunities to collaborate and create new ideas,
										validate and build on existing knowledge and good practice
										to accelerate learning.{" "}
									</li>
								</ul>
							</p>
							<div className="header-text">Target Audience</div>

							<p
								style={{
									fontSize: "20px",
									textAlign: "center",
									textJustify: "interword",
								}}
							>
								This CoP is targeted to state commercial tobacco control program
								managers with less than two years of commercial tobacco control
								experience.
							</p>
							<div className="header-text">Submitting an Application</div>
							<hr />
							{/* <p
								style={{
									fontWeight: "bold",
									fontSize: "20px",
									textAlign: "center",
									marginBottom: "20px",
									color: "red",
								}}
							>
								Registration is now closed. If you have applied for this CoP,
								you will receive a decision by December 16th.
							</p> */}
							<p>
								<span
									style={{
										fontWeight: "bold",
										fontSize: "20px",
										textAlign: "left",
										marginBottom: "20px",
									}}
								>
									To apply for this CoP, fill out the information below. Once
									you apply, you will receive a confirmation email notifying you
									that we have received your application. You will then be
									notified on December 16th, 2024 regarding your application
									status. In the meantime, please block the CoP dates and times
									on your calendar to be sure you will be able to attend if
									accepted.
								</span>
							</p>
							<p
								style={{
									fontSize: "20px",
									textAlign: "justify",
									textJustify: "interword",
								}}
							>
								{questions.map((question) => {
									let tempType = "text";
									if (question.type == 0) {
										tempType = "text";
										return (
											<Form.Group
												key={question.questionid}
												className="mb-3"
												controlId={`form${question.questionid}`}
											>
												<Form.Label>{question.question}</Form.Label>
												<Form.Control
													type={tempType}
													// placeholder={question.question}
													question={`q${question.questionid}`}
													onChange={handleForm}
												/>
											</Form.Group>
										);
									} else if (question.type == 1) {
										tempType = "radio";
										return (
											<Form.Group
												key={question.questionid}
												className="mb-3"
												controlId={`form${question.questionid}`}
											>
												<Form.Label>{question.question}</Form.Label>
												{question.answers.map((answer) => {
													return (
														<Form.Check
															type={tempType}
															name={"group" + question.questionid}
															label={answer.choice_descr}
															placeholder={question.question}
															question={`q${question.questionid}`}
															answer={answer.choiceid}
															onChange={handleFormCheck}
														/>
													);
												})}
											</Form.Group>
										);
									} else if (question.type == 2) {
										tempType = "checkbox";
										return (
											<Form.Group
												key={question.questionid}
												className="mb-3"
												controlId={`form${question.questionid}`}
											>
												<Form.Label>{question.question}</Form.Label>
												{question.answers.map((answer) => {
													return (
														<Form.Check
															type={tempType}
															label={answer.choice_descr}
															placeholder={question.question}
															question={`q${question.questionid}`}
															answer={answer.choiceid}
															onChange={handleFormCheck}
														/>
													);
												})}
											</Form.Group>
										);
									}
								})}
							</p>
							<p style={{ textAlign: "center" }}>
								<strong>
									Please note: by submitting your application, you are attesting
									to attending at least 3 of the 4 CoP sessions.
								</strong>
							</p>
							<div style={{ textAlign: "center" }}>
								{JSON.stringify(auth) === "{}" ? (
									<Button variant="success" disabled>
										Please login to apply for this CoP.
									</Button>
								) : (
									<Button
										variant="success"
										onClick={clickHandler}
										disabled={!check}
									>
										Click Here to Submit Your Application
									</Button>
								)}

								<p style={confirmationStyle}>
									<strong>{message !== "" && message}</strong>
								</p>
							</div>
							{/* </p> */}
							{/* <div className="header-text">Abstract Process</div>
							<p style={{ fontSize: "20px", textAlign: "center" }}>
								<strong>
									Abstract Submissions are Now Closed. The Review Process is
									Underway.
								</strong>
							</p> */}
							{/* <p></p> */}
							{/* <p>
								We are seeking abstracts showcasing the lessons learned, process
								and implementation highlights, challenges and breakthroughs,
								emerging issues and trends, and accomplishments during the
								implementation of the DP20-2001. We encourage and welcome
								proposals from CDC-OSH awardees across all states and islands.
							</p>
							<p>
								<strong>Start Here! </strong>
								<a href="https://forms.gle/ewrMACA1UrjQW8KB7" target="_blank">
									CLICK HERE
								</a>{" "}
								to begin the online abstract submission process.
							</p>
							<p>
								<strong>Abstract Guidelines One-Pager.</strong> The following
								document is a one-paged resource that answers questions you may
								have regarding the abstract submission process:{" "}
								<a
									href="https://www.tctcollab.com/assets/am23/2024%20State%20Showcase%20Abstracts%20Guidance_092823.pdf"
									target="_blank"
								>
									2024 State Showcase Abstracts Guidance
								</a>
							</p>
							<p>
								<strong>Submission Deadline.</strong> The deadline for abstract
								submissions has been extended to <strong>October 20th</strong>.
								It is our hope that this will provides a little more room to
								successfully submit an abstract.
							</p>
							<p>
								<strong>Microsoft Word Submission Form.</strong> For those who
								are unable to access the online submission form, please{" "}
								<a
									href="https://www.tctcollab.com/assets/am23/2024%20Abstract%20Submission%20Form-FNL.docx"
									target="_BLANK"
								>
									CLICK HERE
								</a>{" "}
								for a Word version of the submission form that can be completed
								and submitted via email to Bonita McGee (
								<a href="mailTo:brmcgee@catalystcf.com">
									brmcgee@catalystcf.com
								</a>
								) with the subject line: "--Organization Name-- Abstract
								Submission".
							</p>
							<p>
								<strong>Hybrid Option Update. </strong>We will not have a hybrid
								option for the upcoming awardee meeting. We encourage you to
								utilize alternate means of bringing voice to those who will not
								be physically present via video, Loom, or equivalent
								technologies, should travel not be an option.
							</p>
							<h4>Q&A</h4>
							<p>
								<strong>
									Is it possible to extend the abstract submission deadline past
									the current deadline of October 16, 2023?
								</strong>
							</p>
							<p>
								The deadline has been extended from the October 16th to October
								20th. This newly extended deadline takes into account the time
								required to review, select, inform, and prepare for the meeting
								beyond the submission close date. (PSB/TCTC)
							</p>
							<p>
								<strong>
									If a state program is unable to get travel approved to travel
									outside of the state in March 2024 to attend the Awardee
									Meeting, can they send their Local Lead Agency (LLA) - since
									the LLA would not have to have state approval to travel, if it
									comes down to that?
								</strong>
							</p>
							<p>
								We were not planning to include local staff or partners in this
								meeting, generally speaking. All of the sessions are being built
								with state programs in mind so LLAs would find it difficult to
								find relevant content for their needs. (PSB/TCTC)
							</p>
							<p>
								<strong>Are states limited to 3 abstracts? </strong>
							</p>
							<p>Yes. Only 3 submissions, max, are allowed. (TCTC)</p>
							<p>
								<strong>
									Do states (not the partners) submit the abstract?
								</strong>
							</p>
							<p>States are the lead and must submit the abstract. (TCTC)</p>
							<p>
								<strong>Still Have Questions? </strong>If you are still on the
								fence, don’t hesitate to contact your project officer, national
								network, or any other partner to consider how to represent your
								state’s contributions and achievements best.{" "}
							</p>
							<p>
								For additional questions about the abstract submission process
								not answered by the guidance document provided, please contact
								Bonita McGee (
								<a href="mailTo:brmcgee@catalystcf.com">
									brmcgee@catalystcf.com
								</a>
								).
							</p> */}

							<div className="header-text">Questions?</div>
							<p style={{ fontSize: "20px", textAlign: "center" }}>
								<strong>
									Contact us:{" "}
									<a href="mailTo:support@tctcollab.com">
										support@tctcollab.com
									</a>
								</strong>
							</p>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	);
}
