import React from "react";
import {
	Col,
	Container,
	Row,
	Table,
	Button,
	InputGroup,
	FormControl,
	Image,
	Form,
} from "react-bootstrap";
import Menu from "./menu";
import MyButton from "./mybutton";
import { useEffect, useState } from "react";
import Box from "./box";
import BoxList from "./boxlist";
import { type } from "@testing-library/user-event/dist/type";
import Steps from "./steps";
import { useLocation } from "react-router-dom";
import BarChart from "./barchart";
import Map from "./map";
import DataTable from "./datatable";
import EventMap from "./eventmap";
import EventDataTable from "./eventdatatable";
import { useContext } from "react";
import AuthContext from "../context/authprovider";
import UtsWelcome from "./utswelcome";

export default function UT() {
	const location = useLocation();
	const [input, setInput] = useState([]);
	const [map, setMap] = useState(0);
	const [stateClicks, setStateClicks] = useState([]);
	const [stateRegistered, setStateRegistered] = useState([]);
	const [statePmsRegistered, setStatePmsRegistered] = useState([]);
	const [userRoles, setUserRoles] = useState([]);
	const [events, setEvents] = useState([]);
	const [eventSelection, setEventSelection] = useState();
	const [show, setShow] = useState([0, 0, 0]);
	const { auth } = useContext(AuthContext);
	const [welcome, setWelcome] = useState(true);
	// console.log(auth);

	const buttonClick = (b) => {
		let x = show;
		x[0] = b;
		x[1] = 0;
		let temp = x[2];
		setShow([b, 0, temp]);
	};
	const buttonClick2 = (b) => {
		if (b !== "-1") {
			// console.log(b)
			let x = show;
			setShow([x[0], parseInt(b), x[2]]);
		}
		setWelcome(false);
		// console.log(show)
	};
	const buttonClick3 = (b) => {
		let x = show;
		// console.log(x);
		setShow([x[0], parseInt(x[1]), b]);
	};
	useEffect(() => {
		fetch("https://www.api.tctcollab.com/resources/tracking/clicks/competency")
			.then((results) => results.json())
			.then((data) => {
				// console.log(data);
				setInput(data);
				// console.log(data);
			});
		fetch("https://www.api.tctcollab.com/info/us")
			.then((results) => results.json())
			.then((data2) => {
				// console.log(data);
				setMap(data2);
				// console.log(data);
				//
			});
		fetch("https://www.api.tctcollab.com/resources/tracking/clicks/states")
			.then((results) => results.json())
			.then((data) => {
				// console.log(data);
				setStateClicks(data);
				// console.log(data);
				//
			});
		fetch("https://www.api.tctcollab.com/user/tracking/state")
			.then((results) => results.json())
			.then((data) => {
				// console.log(data);
				setStateRegistered(data);
				// console.log(data);
				//
			});
		fetch("https://www.api.tctcollab.com/user/tracking/pms/state")
			.then((results) => results.json())
			.then((data) => {
				// console.log(data);
				setStatePmsRegistered(data);
				// console.log(data);
				//
			});
		fetch("https://www.api.tctcollab.com/user/tracking/roles")
			.then((results) => results.json())
			.then((data) => {
				// console.log(data);
				setUserRoles(data);
				// console.log(data);
			});
		fetch("https://www.api.tctcollab.com/event/all")
			.then((results) => results.json())
			.then((data) => {
				// console.log(data);
				setEvents(data);
				// console.log(data);
			});
		fetch(`https://www.api.tctcollab.com/tracking/sitevisit`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ page: "UTS" }),
		});
	}, []);
	return (
		<Container>
			<Row>
				<Col xl="3">
					<Menu>
						{[1, 2, 3, 4, 6].includes(auth.rightid) && (
							<MyButton
								variant="purple"
								style={{ width: "100%" }}
								onClick={() => buttonClick(1)}
							>
								View Resource Data
							</MyButton>
						)}
						<MyButton
							variant="purple"
							style={{ width: "100%" }}
							onClick={() => buttonClick(2)}
						>
							View Site Registration Data
						</MyButton>
						<MyButton
							variant="purple"
							style={{ width: "100%" }}
							onClick={() => buttonClick(3)}
						>
							View Event Data
						</MyButton>
					</Menu>
					{show[0] !== 0 && (
						<Menu>
							{show[0] === 1 && [1, 2, 3, 4, 6].includes(auth.rightid) && (
								<MyButton
									variant="orange"
									style={{ width: "100%" }}
									onClick={() => buttonClick2(1)}
								>
									Resources Clicked by Competency
								</MyButton>
							)}
							{show[0] === 1 && (
								<MyButton
									variant="orange"
									style={{ width: "100%" }}
									onClick={() => buttonClick2(2)}
								>
									Organizations Viewing Resources
								</MyButton>
							)}
							{show[0] === 2 && (
								<MyButton
									variant="orange"
									style={{ width: "100%" }}
									onClick={() => buttonClick2(1)}
								>
									Registration by State
								</MyButton>
							)}
							{show[0] === 2 && (
								<MyButton
									variant="orange"
									style={{ width: "100%" }}
									onClick={() => buttonClick2(2)}
								>
									PM Regsitration by State
								</MyButton>
							)}
							{show[0] === 2 && [1, 2, 3, 4, 6].includes(auth.rightid) && (
								<MyButton
									variant="orange"
									style={{ width: "100%" }}
									onClick={() => buttonClick2(3)}
								>
									Users Registered by Role
								</MyButton>
							)}
							{show[0] === 3 && (
								<Form.Select onChange={(ev) => buttonClick2(ev.target.value)}>
									<option key="-1" value="-1" selected>
										Select an Event
									</option>
									{events.map((e) => {
										return (
											<option key={e.eventid} value={e.eventid}>
												{e.name}
											</option>
										);
									})}
								</Form.Select>
							)}
						</Menu>
					)}
					{show[1] !== 0 && (
						<Menu>
							<MyButton
								variant="orange"
								style={{ width: "100%" }}
								onClick={() => buttonClick3(0)}
							>
								Visualization
							</MyButton>
							<MyButton
								variant="orange"
								style={{ width: "100%" }}
								onClick={() => buttonClick3(1)}
							>
								Table
							</MyButton>
						</Menu>
					)}
				</Col>
				<Col xl="9">
					{show[1] == 0 ? (
						<UtsWelcome />
					) : (
						<div id="dashboard-container">
							{JSON.stringify(show) == JSON.stringify([1, 1, 0]) && (
								<BarChart
									id="resource-clicks-by-competency"
									data={input}
									title="# of Resources Clicked per Competency"
									header={["Competencies", "# of Clicks"]}
								/>
							)}
							{JSON.stringify(show) == JSON.stringify([1, 1, 1]) && (
								<DataTable
									id="resource-clicks-by-competency-table"
									headers={["Competency", "Resources Clicked"]}
									data={input}
								/>
							)}
							{/* resources clicked by state */}
							{JSON.stringify(show) == JSON.stringify([1, 2, 0]) &&
								map !== 0 &&
								stateClicks !== [] && (
									<Map
										id="resources-clicked-by-state"
										data={map}
										clicks={stateClicks}
										title="Resources Clicked by State"
										headers={["State", "Clicks"]}
									/>
								)}
							{JSON.stringify(show) == JSON.stringify([1, 2, 1]) &&
								stateClicks !== [] && (
									<DataTable
										id="resources-clicked-by-state-table"
										headers={["State", "Clicks"]}
										data={stateClicks}
									/>
								)}
							{/* registered by state */}
							{JSON.stringify(show) == JSON.stringify([2, 1, 0]) &&
								map !== 0 &&
								stateRegistered !== [] && (
									<Map
										id="registered-by-state-map"
										data={map}
										clicks={stateRegistered}
										title="Registered Users by State"
										headers={["State", "Registered"]}
									/>
								)}
							{JSON.stringify(show) == JSON.stringify([2, 1, 1]) &&
								map !== 0 &&
								stateRegistered !== [] && (
									<DataTable
										id="registered-by-state-map-table"
										headers={["State", "Registered"]}
										data={stateRegistered}
									/>
								)}
							{/* pms registered by state */}
							{JSON.stringify(show) == JSON.stringify([2, 2, 0]) &&
								map !== 0 &&
								statePmsRegistered !== [] && (
									<Map
										id="pms-registered-by-state-map"
										data={map}
										title="PMs Registered by State"
										headers={["State", "Pms Registered"]}
										clicks={statePmsRegistered}
									/>
								)}
							{JSON.stringify(show) == JSON.stringify([2, 2, 1]) &&
								map !== 0 &&
								statePmsRegistered !== [] && (
									<DataTable
										id="pms-registered-by-state-table"
										headers={["State", "Pms Registered"]}
										data={statePmsRegistered}
									/>
								)}
							{/* Roles by User */}
							{JSON.stringify(show) == JSON.stringify([2, 3, 0]) && (
								<BarChart
									id="roles-by-user-bar"
									data={userRoles}
									title="Users Registered by Role"
									header={["Roles", "# Registered"]}
								/>
							)}
							{JSON.stringify(show) == JSON.stringify([2, 3, 1]) && (
								// console.log(show)
								<DataTable
									id="roles-by-user-table"
									headers={["Roles", "# Registered"]}
									data={userRoles}
								/>
							)}
							{show[0] == 3 && show[1] !== 0 && show[2] === 0 && (
								<EventMap
									id="ntcp-attendance-map"
									data={map}
									title="NTCP Webinar Attendance"
									headers={["State", "# of Attendees"]}
									eventid={show[1]}
								/>
							)}
							{show[0] == 3 && show[1] !== 0 && show[2] === 1 && (
								<EventDataTable id="ntcp-attendance-table" eventid={show[1]} />
							)}
						</div>
					)}
				</Col>
			</Row>
		</Container>
	);
}
