import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Table } from "react-bootstrap";
export default function Results(props) {
	const style = {
		maxWidth: "800px",
		textAlign: "center",
		margin: "auto",
		boxShadow: "5px 5px 5px grey",
	};
	const [results, setResults] = useState([]);
	const [comps, setComps] = useState([]);
	const [selected, setSelected] = useState("");
	const [answers, setAnswers] = useState([]);
	const clickHandler = (c) => {};
	useEffect(() => {
		fetch(`https://www.api.tctcollab.com/itp/results/${props.user}`, {
			headers: {
				"Cache-Control": "no-cache",
			},
		})
			.then((res) => res.json())
			.then((data) => {
				// console.log(data);
				setResults(data);
				let temp = [];
				let count = 0;
				// console.log(data.length);
				data.forEach((question) => {
					if (!temp.includes(question.descr)) {
						// console.log(question.descr);
						temp.push(question.descr);
						// console.log(temp);
					}
					if (count == data.length) {
						setComps(temp);
						// console.log(comps);
					}
					setComps(temp);
					count++;
				});
			});
	}, []);
	useEffect(() => {
		let temp = [];
		results.forEach((result) => {
			// console.log(result);

			if (result.descr == selected) {
				temp.push(result);
			}
			setAnswers(temp);
		});
	}, [selected]);
	return (
		<div className="results-container">
			<Row>
				<Col lg="3">
					{comps.map((competency) => {
						// console.log(comps);
						return (
							<div
								className="competency"
								onClick={() => setSelected(competency)}
							>
								{competency}
							</div>
						);
					})}
				</Col>
				<Col lg="9">
					<h4>{selected}</h4>
					<Table>
						<thead>
							<tr>
								<th>Question</th>
								<th>Correct Answer</th>
								<th>Explanation</th>
							</tr>
						</thead>
						<tbody>
							{answers.map((info) => {
								return (
									<tr
										className={`answer ${info.answer == info.correct_answer}`}
									>
										<td>{info.question}</td>
										<td>{info[`answer_${info.correct_answer}`]}</td>
										<td>{info.explanation}</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Col>
			</Row>
		</div>
	);
}
