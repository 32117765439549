import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Table } from "react-bootstrap";
export default function OnboardingReport(props) {
	const style = {
		maxWidth: "800px",
		textAlign: "center",
		margin: "auto",
		boxShadow: "5px 5px 5px grey",
	};
	const [pms, setPms] = useState([]);
	// const [comps, setComps] = useState([]);
	// const [selected, setSelected] = useState("");
	// const [answers, setAnswers] = useState([]);
	// const clickHandler = (c) => {};
	useEffect(() => {
		fetch(`https://www.api.tctcollab.com/report/pmonboarding/pms`, {
			headers: {
				"Cache-Control": "no-cache",
			},
		})
			.then((res) => res.json())
			.then((data) => {
				// console.log(data);
				setPms(data);
			});
	}, []);
	return (
		<div className="onboarding-report-container">
			<p>
				Please find a list of all Program Managers hired since January 1st, 2021
				and their onboarding status.
			</p>
			<Row>
				<Col>
					<Table>
						<thead>
							<tr>
								<th>Public Health Analyst</th>
								<th>Name</th>
								<th>Title</th>
								<th>Organization</th>
								<th>Onboarding?</th>
								<th>% Complete</th>
								<th>Hire Date</th>
							</tr>
						</thead>
						<tbody>
							{pms.map((info) => {
								if (info.hire_date > "2021-01-01") {
									return (
										<tr className={`pm ${info.Percent_Complete > 80}`}>
											<td>{info.pha_fname + " " + info.pha_lname}</td>
											<td>{info.fname + " " + info.lname}</td>
											<td>{info.title}</td>
											<td>{info.descr}</td>
											<td>{info.isonboarding ? "Yes" : "No"}</td>
											<td>
												{info.Percent_Complete > 100
													? "100%"
													: info.Percent_Complete + "%"}
											</td>
											<td>{info.hire_date.split("T")[0]}</td>
										</tr>
									);
								}
							})}
						</tbody>
					</Table>
				</Col>
			</Row>
		</div>
	);
}
