import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Webinar from "../img/webinars2.jpg";
import Training from "../img/training.jpg";
import Bp from "../img/bestpractices.jpg";
import Reference from "../img/refmaterials.jpg";
import Sgr from "../img/sgr.jpg";
import Ppt from "../img/ppti.jpeg";
import Conference from "../img/conference.jpeg";
import AuthContext from "../context/authprovider";
import { useContext } from "react";
export default function Box(props) {
	const { auth } = useContext(AuthContext);
	const [active, setActive] = useState(false);
	const [description, setDescription] = useState(false);
	// const [titleStyle,setTitleStyle]=useState({});
	const [color, setColor] = useState("");
	const [bgColor, setBgColor] = useState("");
	const [bgImage, setBgImage] = useState();
	const [display, setDisplay] = useState("");
	const [isShown, setIsShown] = useState(true);
	const clickHandler = () => {
		setActive(!active);
		// console.log(active);
		if (!active) {
			setTimeout(() => setDescription(true), 500);
		} else setDescription(false);
	};
	const trackClick = (resourceid) => {
		let data = {
			userid: auth.userid,
			resourceid,
		};
		fetch(`https://www.api.tctcollab.com/tracking/resourceclick`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((results) => results.json())
			.then((data) => {});
	};
	const titleStyleContainer = {
		color: color,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: bgColor,
		minHeight: "68px",
	};
	const titleStyle = {
		fontSize: "16px",
		padding: "10px",
	};
	const boxStyleContainer = {
		display: display,
		// backgroundSize:"cover",
		backgroundImage: `url(${bgImage})`,
	};
	const descriptionStyle = {
		overflowY: "auto",
		background: "rgba(220,220,220,.9)",
		// height:"100%"
	};
	useEffect(() => {
		!isShown && setDisplay("none");
		switch (props.type) {
			case "Webinar": {
				setBgColor("rgb(251, 255, 0)");
				setColor("black");
				setBgImage(Webinar);
				break;
			}
			case "Online Course": {
				setBgColor("rgb(57,127,12)");
				setColor("white");
				setBgImage(Training);
				break;
			}
			case "Best Practice": {
				setBgColor("rgb(139,20,213)");
				setColor("white");
				setBgImage(Bp);
				// setTitleStyle({backgroundColor:bgColor,color:color})
				break;
			}
			case "Surgeon General Report": {
				setBgColor("rgb(255,150,34)");
				setColor("black");
				setBgImage(Sgr);
				break;
			}
			case "Reference Material": {
				setBgColor("#0ADAFF");
				setColor("black");
				setBgImage(Reference);
				// setTitleStyle({backgroundColor:bgColor,color:color})
				break;
			}
			case "Powerpoint": {
				setBgColor("rgb(184, 54, 15)");
				setColor("white");
				setBgImage(Ppt);
				break;
			}
			case "Conference": {
				setBgColor("rgb(4, 0, 112)");
				setColor("white");
				setBgImage(Conference);
				break;
			}
			default: {
				setBgColor("");
				setColor("black");
				// setTitleStyle({backgroundColor:bgColor,color:color})
			}
		}
	}, []);
	return (
		<div
			className={active ? "box active" : "box"}
			onClick={clickHandler}
			style={boxStyleContainer}
		>
			<div className="box-spacer">
				{description ? (
					<div
						className="box-description"
						style={descriptionStyle}
						dangerouslySetInnerHTML={{ __html: props.description }}
					>
						{/* {props.description} */}
					</div>
				) : null}
			</div>
			<div className="box-title" style={titleStyleContainer}>
				{props.type == "NTCP" ? (
					<Link
						to={`${props.id}`}
						style={{ color: color, textDecoration: "none" }}
					>
						<h4>{props.title}</h4>
					</Link>
				) : (
					<h4
						style={titleStyle}
						onClick={() => {
							trackClick(props.id);
							window.open(`${props.url}`, "_blank");
						}}
					>
						{props.title}
					</h4>
				)}
			</div>
		</div>
	);
}
