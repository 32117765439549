import React, { useEffect, useState } from "react";
import CourseMenu from "./coursemenu";
import { Container, Row, Col, Form } from "react-bootstrap";
import CourseOptionBox from "./courseoptionbox";
import MyButton from "./mybutton";
import Steps from "./steps";
import Guide from "./guide";
import AuthContext from "../context/authprovider";
import { useContext } from "react";
import { useLinkClickHandler, useLocation } from "react-router-dom";
import CourseBox from "./coursebox";
export default function PhaOnboarding() {
	const location = useLocation();
	const [coursePage, setCoursePage] = useState(-1);
	const [courseArray, setCourseArray] = useState([]);
	const [complete, setComplete] = useState(false);
	const [guide, setGuide] = useState(true);
	const [search, setSearch] = useState("");
	const [showNew, setShowNew] = useState(false);
	const [showOnboarding, setShowOnboarding] = useState(false);
	///PULL PHA ID FROM CONTEXT
	const { auth } = useContext(AuthContext);
	const [phaid, setPhaid] = useState(auth.userid);

	const clickHandler = (x) => {
		if (x == "next") {
			// console.log(courseArray[coursePage].taskid);
			fetch(
				`https://www.api.tctcollab.com/phaonboarding/next/${courseArray[coursePage].taskid}/pha/${phaid}`,
				{
					headers: {
						"Cache-Control": "no-cache",
					},
				}
			);
			if (coursePage != courseArray.length - 1) {
				let temp = coursePage;
				temp++;
				setCoursePage(temp);
				setComplete(false);
				// console.log(temp);
			}
		} else if (x == "start") {
			// console.log(courseArray[coursePage].taskid);
			fetch(`https://www.api.tctcollab.com/phaonboarding/start/${phaid}`, {
				headers: {
					"Cache-Control": "no-cache",
				},
			})
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					if (data[0].task !== null) {
						setCoursePage(data[0].task);
					} else {
						setCoursePage(0);
					}
					setComplete(false);
					// console.log(data);
					// console.log(data);
				});
		} else if (x == "complete") {
			// console.log(courseArray[coursePage].taskid);
			fetch(
				`https://www.api.tctcollab.com/phaonboarding/next/${courseArray[coursePage].taskid}/pha/${phaid}`,
				{
					headers: {
						"Cache-Control": "no-cache",
					},
				}
			).then(setComplete(true));
		} else {
			if (coursePage != 0) {
				let temp = coursePage;
				temp--;
				setCoursePage(temp);
				setComplete(false);
			}
		}
	};

	useEffect(() => {
		// pull all the users grantees and load them into the fArray
		fetch(`https://www.api.tctcollab.com/phaonboarding/tasks/${phaid}`, {
			headers: {
				"Cache-Control": "no-cache",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setCourseArray(data);
				// console.log(data);
				// console.log(data);
			});
		fetch(`https://www.api.tctcollab.com/tracking/sitevisit`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ page: "PHA Onboarding" }),
		});
	}, []);

	return (
		<Container>
			<Row>
				<Col lg="3">
					<div className="course-menu-container">
						{courseArray.map((course, index) => {
							// index > 0 && console.log(course);
							// console.log(
							// 	course.section + " " + courseArray[index - 1].section
							// );
							return index == coursePage ? (
								<CourseOptionBox
									// key={index}
									active={true}
									title={course.title}
									section={course.category}
									onClick={() => {
										setCoursePage(index);
										setComplete(false);
									}}
									indent={
										index !== 0
											? course.category == courseArray[index - 1].category
											: false
									}
									key={course.taskid + course.task_name}
									// key={courseArray[coursePage].taskid}
								/>
							) : (
								<CourseOptionBox
									// key={index}
									active={false}
									title={course.task_name}
									section={course.category}
									onClick={() => {
										setCoursePage(index);
										setComplete(false);
									}}
									indent={
										index !== 0
											? course.category == courseArray[index - 1].category
											: false
									}
									key={course.taskid + course.task_name}
								/>
							);
						})}
						{/* <CourseOptionBox active={true} title="Introduction" section="0"/>
                           <CourseOptionBox active={false} title="Chapter 1" section="1"/>
                           <CourseOptionBox active={false} title="Chapter 1.1" section="1"/> */}
					</div>
				</Col>
				<Col lg="9">
					<div
						className="boxContainer"
						style={{
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "space-around",
						}}
					>
						{coursePage == -1 ? (
							<div className="itp-instructions">
								<h3>Welcome to the PHA Onboarding System</h3>
								<p>
									Please click "Start" to begin navigating the course. Clicking
									the "Next" button as you make your way through, will mark each
									page as completed.
								</p>
							</div>
						) : complete ? (
							<div className="itp-instructions">
								<h3>
									Congratulations on Reaching the end of the PHA Onboarding!
								</h3>
								<p>
									Please feel free to return at any time to review the contents
									located here. You can now click on the menu on the left to
									continue to navigare through the onboarding.
								</p>
							</div>
						) : (
							<CourseBox
								title={courseArray[coursePage].task_name}
								img={courseArray[coursePage].img}
								description={courseArray[coursePage].task_description}
								link={courseArray[coursePage].link}
								id={courseArray[coursePage].taskid}
								category={courseArray[coursePage].category}
							/>
						)}
					</div>
					<div style={{ float: "right" }}>
						{coursePage == 0 ? (
							<div className="itp-button-container">
								<MyButton onClick={() => clickHandler("prev")}>Prev</MyButton>
							</div>
						) : null}
						{coursePage == courseArray.length - 1 ? (
							<div className="itp-button-container">
								<MyButton
									onClick={() => clickHandler("complete")}
									variant="purple"
								>
									Complete
								</MyButton>
							</div>
						) : coursePage == -1 ? (
							<div className="itp-button-container">
								<MyButton onClick={() => clickHandler("start")}>Start</MyButton>
							</div>
						) : (
							<div className="itp-button-container">
								<MyButton onClick={() => clickHandler("next")}>Next</MyButton>
							</div>
						)}
					</div>
				</Col>
			</Row>
		</Container>
	);
}
