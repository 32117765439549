import React from "react";
import { Button } from "react-bootstrap";
import {
	Table,
	InputGroup,
	FormControl,
	Form,
	Container,
	Row,
	Col,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Lsslogo from "../img/LSSlogo.png";

export default function LssMeeting(props) {
	const [orgs, setOrgs] = useState([]);
	const [primaryOrg, setPrimaryOrg] = useState("");
	const [roles, setRoles] = useState([]);
	const [questions, setQuestions] = useState([]);
	const [role, setRole] = useState("");
	const [check, setCheck] = useState(false);
	const [email, setEmail] = useState("");
	const [title, setTitle] = useState("");
	const [fname, setFname] = useState("");
	const [lname, setLname] = useState("");
	const [message, setMessage] = useState("");
	const [questionArray, setQuestionArray] = useState({
		q1: "",
		q2: "",
		q3: "",
	});
	const clickHandler = () => {
		if (
			role !== "" &&
			email !== "" &&
			title !== "" &&
			fname !== "" &&
			lname !== "" &&
			primaryOrg !== "" &&
			questionArray["q1"] !== "" &&
			questionArray["q2"] &&
			questionArray["q3"]
		) {
			let data = {
				role,
				email,
				title,
				fname,
				lname,
				primaryOrg,
				eventid: 31,
				q1: questionArray["q1"],
				q2: questionArray["q2"],
				q3: questionArray["q3"],
			};
			fetch(`https://www.api.tctcollab.com/event/register/submit`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
				.then((data) => data.json())
				.then((data) => {
					setMessage(data);
				});
		} else {
			setMessage("Please fill out all information and try again.");
		}
	};
	const handlePrimaryOrg = (event) => {
		setPrimaryOrg(event.target.value);
	};
	const handleRole = (event) => {
		setRole(event.target.value);
	};
	const handleCheck = (event) => {
		setCheck(!check);
		// console.log(check);
	};
	const handleForm = (event) => {
		let temp = questionArray;
		temp[event.target.getAttribute("question")] = event.target.value;
		setQuestionArray(temp);
	};
	const handlefname = (event) => {
		setFname(event.target.value);
	};
	const handlelname = (event) => {
		setLname(event.target.value);
	};
	const handleTitle = (event) => {
		setTitle(event.target.value);
	};
	const handleEmail = (event) => {
		setEmail(event.target.value);
	};
	useEffect(() => {
		fetch(`https://www.api.tctcollab.com/event/orgs`)
			.then((data) => data.json())
			.then((data) => {
				setOrgs([...data]);
			});
	}, []);
	useEffect(() => {
		fetch(`https://www.api.tctcollab.com/info/roles`)
			.then((data) => data.json())
			.then((data) => {
				setRoles([...data]);
			});
	}, []);
	useEffect(() => {
		fetch(`https://www.api.tctcollab.com/event/register/questions/31`)
			.then((data) => data.json())
			.then((data) => {
				setQuestions([...data]);
			});
	}, []);
	return (
		<div className="nn-container">
			<Container>
				<div className="info-container">
					<Row>
						<Col>
							<img src={Lsslogo} rel="Leadership and Sustainability School" />
							{/* <h1>Mining Lessons Learned and their Impact on the Future</h1> */}
							<p className="sub-header" style={{ fontSize: "25px" }}>
								2023 CDC-OSH Leadership and Sustainability Summer School
								<br />
								<span
									style={{
										fontStyle: "italic",
										fontSize: "25px",
										// fontWeight: "normal",
										color: "blue",
									}}
								>
									Reflect | Refresh | Reinforce | Reinvigorate
								</span>
							</p>
							<hr />
							<div className="header-text">Purpose and Format</div>
							<p style={{ textAlign: "justify" }}>
								The 2023 Leadership and Sustainability Summer School will
								provide an opportunity for members of all previous L&SS cohorts
								to gather over the course of eight weeks to focus on their
								continued leadership development and growth.
							</p>
							<p style={{ textAlign: "justify" }}>
								The meeting will take place{" "}
								<strong>
									<em>virtually</em>
								</strong>{" "}
								via Zoom on June 26, July 10, July 24, and August 7, 2023 from
								3:00 – 5:00 PM ET each day. There will be an estimated time
								commitment between sessions of 1 -3 hours depending on the level
								of engagement with the assignments.
							</p>
							<p>
								For questions about this year's Summer School, contact Bonita
								McGee at{" "}
								<a href="mailTo:brmcgee@catalystcf.com">
									brmcgee@catalystcf.com
								</a>
								.
							</p>
							<div className="header-text">Objectives</div>

							<p>
								Participation in this year's Summer School will allow alumni to:
								<ul>
									<li>
										Deepen the exploration of elements of leadership and their
										leadership practice.
									</li>
									<li>
										Review and reflect on the knowledge and utilization of L&SS
										leadership tools, frameworks, and methods shared over the
										last five cohorts.
									</li>
									<li>
										Provide opportunity for cohort members to explore and share
										lessons learned in their applied leadership practice through
										the lens of the five L&SS leadership skills.
									</li>
									<li>
										Build a strengthened network of colleagues among members of
										all L&SS.
									</li>
								</ul>
							</p>

							<div className="header-text">Agenda</div>
							<div>
								<Row>
									<Col sm="2" className="agenda-time">
										Monday, June 26
										<br />
										3:00-5:00 PM ET
									</Col>
									<Col>
										<div
											className="agenda-header"
											style={{ textAlign: "left" }}
										>
											Reflect: Leadership Practice & Philosophy evolution
										</div>
										<ul style={{ textAlign: "left" }}>
											<li>
												Describe your leadership philosophy statement and
												practice as it relates to the commercial tobacco control
												field.
											</li>
											<li>
												Demonstrate ability to apply leadership philosophy to
												real world challenges and ambitions.
											</li>
											<li>
												Assess and share the leadership needs of commercial
												tobacco control.
											</li>
										</ul>
									</Col>
								</Row>
								<Row>
									<Col sm="2" className="agenda-time">
										Monday, July 10 <br />
										3:00-5:00 PM ET
									</Col>
									<Col>
										<div
											className="agenda-header"
											style={{ textAlign: "left" }}
										>
											Refresh: L&SS Tools & Frameworks
										</div>
										<ul style={{ textAlign: "left" }}>
											<li>
												Describe core tools and frameworks used by the OSH
												Leadership and Sustainability School
											</li>
											<li>
												Assess an evaluation tool as it relates in supporting
												your leadership practice.
											</li>
											<li>
												Demonstrate the ability to assess and match tools and
												practice to real world situations and challenges in the
												field.
											</li>
										</ul>
									</Col>
								</Row>
								<Row>
									<Col sm="2" className="agenda-time">
										Monday, July 24 <br />
										3:00-5:00 PM ET
									</Col>
									<Col>
										<div
											className="agenda-header"
											style={{ textAlign: "left" }}
										>
											Reinforce: Grounding your leadership practice using the
											L&SS five leadership skills.
										</div>
										<ul style={{ textAlign: "left" }}>
											<li>Describe the L&SS five leadership skills.</li>
											<li>
												Assess the five leadership skills and how they relate to
												the field and in your leadership practice.
											</li>
											<li>
												Demonstrate to ability to connect the leadership skills
												to leadership practice and extract learning principles
												from real world experience.
											</li>
										</ul>
									</Col>
								</Row>
								<Row>
									<Col sm="2" className="agenda-time">
										Monday, August 7<br />
										3:00-5:00 PM ET
									</Col>
									<Col>
										<div
											className="agenda-header"
											style={{ textAlign: "left" }}
										>
											Reinvigorate: Lessons learned and inspiring others to act.
											Building the next generation of leaders needed in
											Commercial Tobacco Control.
										</div>
										<ul style={{ textAlign: "left" }}>
											<li>
												Describe future needs of commercial tobacco control.
											</li>
											<li>
												Design scaffolding for continued leadership development
												in the field.
											</li>
										</ul>
									</Col>
								</Row>
							</div>
							<div className="header-text">Register below</div>
							<div>
								<p style={{ textAlign: "justify" }}>
									The summer school cohort experience will be capped at 25
									participants, on a first come basis. Participants must commit
									to all the required components outlined below:
									<ul>
										<li>
											Pre- and Post-session assignments (~1-3 hours per session)
											to include:
											<ul>
												<li>Readings, videos, podcasts</li>
												<li>
													Discussion and engagement via Zoom
												</li>
											</ul>
										</li>
										<li>
											Virtual 2-hour video-conference session attendance
										</li>
										<li>Zoom platform engagement</li>
										<li>Group work</li>
									</ul>
								</p>
								<Row>
									<Col>
										<Form.Group className="mb-3" controlId="formFname">
											{/* <Form.Label>Job Title</Form.Label> */}
											<Form.Control
												type="text"
												placeholder="First Name"
												onChange={handlefname}
												// value={}
											/>
										</Form.Group>

										<Form.Group className="mb-3" controlId="formLname">
											{/* <Form.Label>Job Title</Form.Label> */}
											<Form.Control
												type="text"
												placeholder="Last Name"
												onChange={handlelname}
												// value={}
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="formBasicEmail">
											{/* <Form.Label>Email addres*</Form.Label> */}
											<Form.Control
												type="email"
												placeholder="Email"
												onChange={handleEmail}
												// value={email}
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="formOrganization">
											{/* <Form.Label>Organization</Form.Label> */}
											<Form.Select onChange={handlePrimaryOrg}>
												<option value="0">Organization</option>
												{orgs.map((org) => {
													return <option value={org.orgid}>{org.descr}</option>;
												})}
											</Form.Select>
											{/* <Form.Control type="password" placeholder="Confirm Password" onChange={handlePrimaryOrg} value={primaryOrg}/> */}
										</Form.Group>
										<Form.Group className="mb-3" controlId="formTitle">
											{/* <Form.Label>Job Title*</Form.Label> */}
											<Form.Control
												type="text"
												placeholder="Job Title"
												onChange={handleTitle}
												// value={title}
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="formRole">
											{/* <Form.Label>Organization</Form.Label> */}
											<Form.Select onChange={handleRole}>
												<option value="0">Role</option>
												{roles.map((role) => {
													return (
														<option value={role.roleid}>{role.descr}</option>
													);
												})}
											</Form.Select>
											{/* <Form.Control type="password" placeholder="Confirm Password" onChange={handlePrimaryOrg} value={primaryOrg}/> */}
										</Form.Group>
									</Col>
									<Col>
										{questions.map((question) => {
											return (
												<Form.Group
													key={question.questionid}
													className="mb-3"
													controlId={`form${question.questionid}`}
												>
													{/* <Form.Label>{question.question}</Form.Label> */}
													<Form.Control
														type="text"
														placeholder={question.question}
														question={`q${question.questionid}`}
														onChange={handleForm}
													/>
												</Form.Group>
											);
										})}

										<InputGroup
											className="mb-3"
											sz="lg"
											style={{ fontSize: "25px" }}
										>
											<InputGroup.Checkbox
												aria-label="Commit to the expectations"
												onChange={handleCheck}
												checked={check}
											/>
											{/* <Form.Control aria-label="Text input with checkbox" /> */}
											<InputGroup.Text>
												I commit to the above expectations for this meeting.{" "}
											</InputGroup.Text>
										</InputGroup>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</div>
				<div style={{ fontSize: "20px", fontWeight: "bold" }}>{message}</div>
				<div style={{ textAlign: "center" }}>
					<Button onClick={clickHandler} disabled={!check}>
						Submit
					</Button>
				</div>
				<hr />
			</Container>
		</div>
	);
}
