import React, { useEffect, useState } from "react";
import Menu from "./menu";
import { Container, Row, Col, Form } from "react-bootstrap";
import OnboardingBox from "./onboardingbox";
import MyButton from "./mybutton";
import Steps from "./steps";
import Guide from "./guide";
import AuthContext from "../context/authprovider";
import { useContext } from "react";
import { useLinkClickHandler, useLocation } from "react-router-dom";
import QuestionBox from "./questionbox";
import Results from "./results";
export default function ITP() {
	const location = useLocation();
	const [question, setQuestion] = useState(-1);
	const [questionsArray, setQuestionsArray] = useState([]);
	const [guide, setGuide] = useState(true);
	const [search, setSearch] = useState("");
	const [showNew, setShowNew] = useState(false);
	const [showOnboarding, setShowOnboarding] = useState(false);
	const [answer, setAnswer] = useState(0);
	const [results, setResults] = useState(false);
	const [load, setLoad] = useState(false);
	///PULL PHA ID FROM CONTEXT
	const { auth } = useContext(AuthContext);
	const [phaid, setPhaid] = useState(auth.userid);
	const shuffle = (a) => {
		for (let i = a.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[a[i], a[j]] = [a[j], a[i]];
		}
		return a;
	};
	const storeAnswer = (a) => {
		// console.log(a);
		setAnswer(a);
	};
	const clickHandler = (x) => {
		if (x == "next") {
			// console.log(answer);
			if (answer !== 0) {
				fetch(
					`https://www.api.tctcollab.com/itp/answer/${answer}/${questionsArray[question].questionid}/pha/${phaid}`,
					{
						headers: {
							"Cache-Control": "no-cache",
						},
					}
				).then(() => {
					setAnswer(0);
				});
			}
			if (question != questionsArray.length - 1) {
				let temp = question;
				temp++;
				setQuestion(temp);
				// console.log(temp);
			}
		} else if (x == "submit") {
			// console.log("submit");
			// console.log(answer);
			fetch(
				`https://www.api.tctcollab.com/itp/answer/${answer}/${questionsArray[question].questionid}/pha/${phaid}`,
				{
					headers: {
						"Cache-Control": "no-cache",
					},
				}
			).then(
				fetch(`https://www.api.tctcollab.com/itp/questions/${phaid}`, {
					headers: {
						"Cache-Control": "no-cache",
					},
				})
					.then((response) => response.json())
					.then((data) => {
						setAnswer(0);
						setQuestionsArray(shuffle(data));
						setQuestion(0);

						// console.log(data);
					})
			);
		} else {
			if (question != 0) {
				let temp = question;
				temp--;
				setQuestion(temp);
			}
		}
	};

	useEffect(() => {
		// pull all itp questions
		fetch(`https://www.api.tctcollab.com/itp/questions/${phaid}`, {
			headers: {
				"Cache-Control": "no-cache",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setQuestionsArray(shuffle(data));
				setLoad(true);
				// console.log(data);
				// console.log(data)
			});
		fetch(`https://www.api.tctcollab.com/tracking/sitevisit`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ page: "ITP" }),
		});
	}, []);

	return (
		<Container>
			<Row>
				<Col>
					<div
						className="boxContainer"
						style={{
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "space-around",
						}}
					>
						{questionsArray.length !== 0 ? (
							question == -1 ? (
								<div className="itp-instructions">
									<h3>
										Welcome to the Individualized Training Plan Assessment
									</h3>
									<p>
										You have not, yet, completed this assessment. At present,
										you have {questionsArray.length} questions remaining to
										complete. Please click next to advance through each one.
										When you are ready to begin, click "Start."
										<br />{" "}
										<strong>
											<em>Please Note:</em>
										</strong>{" "}
										<em>
											Once you click "Next" after answering each question, you
											will <strong>NOT</strong> be able to go back to change any
											of your previous answers
										</em>
									</p>
								</div>
							) : (
								<QuestionBox
									id={questionsArray[question].questionid}
									type={questionsArray[question].type}
									question={questionsArray[question].question}
									img={questionsArray[question].imageurl}
									answer_1={questionsArray[question].answer_1}
									answer_2={questionsArray[question].answer_2}
									answer_3={questionsArray[question].answer_3}
									answer_4={questionsArray[question].answer_4}
									answered={() => storeAnswer}
									// imageid={questionsArray[question].img}
								/>
							)
						) : (
							load && (
								<div className="itp-instructions">
									<h3>Please find your ITP Results Below</h3>
									<Results user={phaid} />
								</div>
							)
						)}
					</div>
					<div style={{ float: "right" }}>
						{/* <div className="itp-button-container">
                                <MyButton onClick={()=>clickHandler('prev')}>Prev</MyButton>
                            </div> */}
						{questionsArray.length !== 0 ? (
							question == questionsArray.length - 1 ? (
								<div className="itp-button-container">
									<MyButton
										onClick={() => clickHandler("submit")}
										// variant="purple"
									>
										Next
									</MyButton>
								</div>
							) : question == -1 ? (
								<div className="itp-button-container">
									<MyButton onClick={() => clickHandler("next")}>
										Start
									</MyButton>
								</div>
							) : (
								<div className="itp-button-container">
									<MyButton onClick={() => clickHandler("next")}>Next</MyButton>
								</div>
							)
						) : null}
					</div>
				</Col>
			</Row>
		</Container>
	);
}
