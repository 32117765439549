import React from "react";
import {
	Col,
	Container,
	Row,
	Table,
	Button,
	InputGroup,
	FormControl,
	Image,
	Form,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export default function EventDataTable(props) {
	const location = useLocation();
	const [info, setInfo] = useState([]);
	const [headers, setHeaders] = useState([]);
	const [stateClicks, setStateClicks] = useState([]);
	const [stateRegistered, setStateRegistered] = useState([]);
	const [statePmsRegistered, setStatePmsRegistered] = useState([]);

	const buttonClick = (b) => {
		if (b == "s") {
		} else if (b == "g") {
		} else if (b == "n") {
			///filter fArray into nArray dont forget ....
		} else if (b == "o") {
			///filter fArray into nArray dont forget ....
		} else {
			///fArray into nArray dont forget ....
		}
	};
	useEffect(() => {
		// setInfo(props.data);
		// console.log(props.data);
		setHeaders(["State", "Attendees"]);
		fetch(
			`https://www.api.tctcollab.com/event/tracking/attendees/${props.eventid}`
		)
			.then((results) => results.json())
			.then((data) => {
				// console.log(data);

				setInfo(data);
				// console.log(document.getElementById(this.props.id));
				// console.log(data);
				//
			});
	}, []);
	useEffect(() => {
		setHeaders(["State", "Attendees"]);
		fetch(
			`https://www.api.tctcollab.com/event/tracking/attendees/${props.eventid}`
		)
			.then((results) => results.json())
			.then((data) => {
				// console.log(data);

				setInfo(data);
				// console.log(document.getElementById(this.props.id));
				// console.log(data);
				//
			});
	}, [props]);
	return (
		<Table>
			<thead>
				<tr>
					{headers.map((d) => {
						return <th key={d}>{d}</th>;
						// console.log(d);
					})}
				</tr>
			</thead>
			<tbody>
				{info.map((d) => {
					return (
						<tr>
							{Object.entries(d).map(([k, v]) => {
								return <td>{v}</td>;
								// console.log(v);
							})}
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
}
