import React from "react";
import { Button } from "react-bootstrap";
import MyButton from "./../mybutton";
import { Form, Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useContext, useEffect } from "react";
import LSSLogo from "../../img/LSSlogo.png";
import AuthContext from "../../context/authprovider";
export default function CoP(props) {
	let params = useParams();
	const { auth, setAuth } = useContext(AuthContext);
	const [npm, setNpm] = useState(true);
	const [yc, setYc] = useState(false);

	// const [password,setPassword]=useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [message, setMessage] = useState("");
	const style = {
		maxWidth: "800px",
		textAlign: "center",
		margin: "auto",
		boxShadow: "5px 5px 5px grey",
	};
	useEffect(() => {
		let saved = localStorage.getItem("user");
		if (saved && JSON.stringify(auth) === "{}") {
			setAuth(JSON.parse(saved));
		}
	}, []);
	useEffect(() => {}, []);
	return (
		<div className="steps-container">
			<div style={{ marginBottom: "20px" }}>
				<Container>
					<Row>
						<div
							style={{
								width: "100%",
								textAlign: "center",
								marginBottom: "30px",
							}}
						>
							<img
								style={{ width: "20%", textAlign: "center" }}
								src={LSSLogo}
							/>
						</div>
					</Row>
					<Row>
						<div className="cop-container npm">
							<div className="curriculum-intro">
								<h3>Zoom Information</h3>
								All sessions will be virtual. Please find the information for
								each meeting below:
								<div className="meeting-info">
									<div>
										<strong>Meeting ID: </strong> 817 6639 3275
										<br />
										<strong>Passcode: </strong>815954
									</div>
									<div style={{ textAlign: "center" }}>
										<Button
											style={{ textAlign: "center" }}
											variant="primary"
											href="https://us02web.zoom.us/meeting/tZUqce-rpzgoHdGfffiIyAwNXgk5fENQRQ6C/ics?icsToken=98tyKuGpqT0sGd2Wsh6CRpwcB4_oM-nxmHZBgqd6mhnjCglKQxTYDuVJZ7RaPuni"
										>
											Meeting Tag
										</Button>
									</div>
									<div style={{ textAlign: "center" }}>
										<Button
											variant="primary"
											href="https://us02web.zoom.us/j/81766393275?"
											target="_BLANK"
										>
											Meeting Link
										</Button>
									</div>
								</div>
							</div>
							<div classname="session-box">
								<div className="header-text">Session 1</div>
								<div className="date-text">
									February 26, 2024 3:00-5:00 PM ET
								</div>
								<p style={{ marginBottom: "30px" }}>
									How Will We Create a Culture of Community and Leadership in
									this Space?
									<ul>
										<li>Adhere to shared community “conditions for success”</li>
										<li>
											Explain why the Leadership & Sustainability School
											intentionally leads with equity
										</li>
										<li>Articulate a leadership philosophy statement</li>
										<li>Establish relationships with cohort members</li>
									</ul>
								</p>
							</div>
							<div classname="session-box">
								<div className="header-text">Session 2</div>
								<div className="date-text">March 25, 2024 3:00-5:00 PM ET</div>
								<p style={{ marginBottom: "30px" }}>
									What Kinds of Leaders Does Commercial Tobacco Control Need
									Right Now?
									<ul>
										<li>
											Describe the types of leadership necessary to advance a
											commercial tobacco control policy and systems change
											agenda in a post-COVID environment
										</li>
										<li>
											Explain important differences between leadership and
											management
										</li>
										<li>
											Integrate collaborative leadership and racial justice
											leadership principles in their day-to-day commercial
											tobacco control work
										</li>
										<li>
											Utilize the Spectrum of Public Participation to guide
											their thinking about, and planning for, commercial tobacco
											control program-related community engagement
										</li>
									</ul>
								</p>
							</div>
							<div classname="session-box">
								<div className="header-text">Session 3</div>
								<div className="date-text">May 20, 2024 3:00-5:00 PM ET</div>
								<p style={{ marginBottom: "30px" }}>
									How Can Systems Thinking Get Us Upstream?
									<ul>
										<li>
											Apply systems thinking methods to identify and analyze
											potential upstream commercial tobacco control
											interventions;
										</li>
										<li>
											Discuss interdependencies and interconnections among
											systems contributing to upstream commercial tobacco
											control solutions; and
										</li>
										<li>
											Assess where leverage points within these interdependent
											and interconnected systems exist to address significant
											barriers to progress using six fundamental concepts of
											systems thinking.
										</li>
									</ul>
								</p>
							</div>
							<div classname="session-box">
								<div className="header-text">Session 4</div>
								<div className="date-text">June 24, 2024 3:00-5:00 PM ET</div>
								<p style={{ marginBottom: "30px" }}>
									Grounded in Equity: Communicating and Collaborating for
									resilient relationships and effective policy change.
									<ul>
										<li>
											Advocate for the use of equity assessments to minimize
											unanticipated/unintended adverse consequences of policy
											change in a variety of contexts, including the analysis of
											proposed policies, institutional practices, programs,
											plans and budgetary decisions;
										</li>
										<li>Explore the role of narrative building in impacting</li>
										<li>
											Understanding the impact of misinformation and
											disinformation in community and how that impacts
											collaboration and policy adoption
										</li>
									</ul>
								</p>
							</div>
							<div classname="session-box">
								<div className="header-text">Session 5</div>
								<div className="date-text">July 22, 2024 3:00-5:00 PM ET</div>
								<p style={{ marginBottom: "30px" }}>
									What are Essential Elements for Communicating Evidence and
									Opportunity to Those We Aim to Inspire to Act?
									<ul>
										<li>
											Utilize strategies for communicating information to
											influence behavior change, engage stakeholders, and
											advocate for health equity and racial justice;
										</li>
										<li>
											Ensure the inclusion of evidence beyond published
											literature and quantitative data (e.g., promising
											practices and lived experience) when making programmatic
											decisions
										</li>
										<li>Understanding data disaggregation </li>
									</ul>
								</p>
							</div>
							<div classname="session-box">
								<div className="header-text">
									Applied Leadership Project Presentations
								</div>
								<div className="date-text">August 26 - 30, 2024</div>
								<p style={{ marginBottom: "30px" }}>
									Presentation of applied tobacco control leadership projects.
									<ul>
										<li>
											Demonstrate ability to comprehend and apply tools and
											frameworks for analytical thinking and ideation.
										</li>
										<li>
											Demonstrate capacity for implementing a project through
											effective teamwork.
										</li>
										<li>
											Demonstrate communication and presentation skills to
											convey complex problems or to surface and discuss
											challenging issues in the field.
										</li>
									</ul>
								</p>
							</div>
						</div>
					</Row>
				</Container>
			</div>
		</div>
	);
}
