import { Container, Image, Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../img/logo.png";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import LoginModal from "./loginmodal";
import { useState } from "react";
import AuthContext from "../context/authprovider";
import { useContext, useEffect } from "react";
export default function NavbarComp() {
	const [login, setLogin] = useState(false);
	const { auth, setAuth } = useContext(AuthContext);

	const loginClick = () => {
		setLogin(!login);
		// console.log("click");
	};
	const logoutClick = () => {
		setAuth({});
		localStorage.removeItem("user");
		// console.log(JSON.stringify(auth))
		// console.log("click");
	};
	useEffect(() => {
		let saved = localStorage.getItem("user");
		if (saved && JSON.stringify(auth) === "{}") {
			setAuth(JSON.parse(saved));
		}
	}, []);
	return (
		<div>
			<Navbar expand="sm" scrolling light>
				<Container fluid>
					<Navbar.Brand as={Link} to="/">
						<Image src={logo} style={{ width: "45px" }} />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav" dark>
						<Nav className="ms-auto">
							{/* <Nav.Link as={Link} to="/">Home</Nav.Link>
                        <Nav.Link  as={Link} to="/tcessentials">Tobacco Control Essentials</Nav.Link>
                        <Nav.Link  as={Link} to="/pmonboarding">Grantee Onboarding</Nav.Link>
                        <Nav.Link  as={Link} to = "/contactus">Contact Us</Nav.Link> */}

							<LinkContainer to="/">
								<Nav.Link>Home</Nav.Link>
							</LinkContainer>
							{/* <LinkContainer to="/upcoming">
								<Nav.Link>Upcoming Events</Nav.Link>
							</LinkContainer> */}
							{/* {JSON.stringify(auth) !== "{}" &&
								[1, 2].includes(auth.rightid) && ( */}
							{JSON.stringify(auth) !== "{}" &&
								[1, 2, 10].includes(auth.rightid) && (
									<NavDropdown title="LSS" id="basic-nav-dropdown">
										<NavDropdown.Item>
											<LinkContainer to="/lss/objectives">
												<Nav.Link>Purpose & Objectives</Nav.Link>
											</LinkContainer>
										</NavDropdown.Item>
										<NavDropdown.Item>
											<LinkContainer to="/lss/curriculum">
												<Nav.Link>Curriculum</Nav.Link>
											</LinkContainer>
										</NavDropdown.Item>
										<NavDropdown.Item>
											<LinkContainer to="/lss/summit">
												<Nav.Link>Leadership Summit</Nav.Link>
											</LinkContainer>
										</NavDropdown.Item>
										<NavDropdown.Item>
											<LinkContainer to="/lss/profiles">
												<Nav.Link>Leadership Profiles</Nav.Link>
											</LinkContainer>
										</NavDropdown.Item>
										{/* <NavDropdown.Divider />
								<NavDropdown.Item>
									<LinkContainer to="/lss/links">
										<Nav.Link>Zoom Chat Links</Nav.Link>
									</LinkContainer>
								</NavDropdown.Item> */}
									</NavDropdown>
								)}
							{/* <LinkContainer to="/webinarseries">
								<Nav.Link>VC Series</Nav.Link>
							</LinkContainer> */}
							{/* )} */}
							{/* <LinkContainer to="/cop">
								<Nav.Link>CoPs</Nav.Link>
							</LinkContainer> */}
							{/* <LinkContainer to="/sbw">
								<Nav.Link>SBW</Nav.Link>
							</LinkContainer> */}
							{/* <LinkContainer to="/awardeemeeting">
								<Nav.Link>2024 Awardee Meeting</Nav.Link>
							</LinkContainer> */}
							{/* <LinkContainer to="/nnmeeting">
								<Nav.Link>NN Meeting</Nav.Link>
							</LinkContainer> */}
							{JSON.stringify(auth) !== "{}" &&
								[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) && (
									<LinkContainer to="/vcseries">
										<Nav.Link>Video-Conference Series</Nav.Link>
									</LinkContainer>
								)}
							{/* {JSON.stringify(auth) !== "{}" &&
								[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) && (
									// [1, 2].includes(auth.rightid) && (
									<LinkContainer to="/npmsbw">
										<Nav.Link>New PM SBW</Nav.Link>
									</LinkContainer>
								)} */}
							{/* {JSON.stringify(auth) !== "{}" &&
								[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) && (
									// [1, 2].includes(auth.rightid) && (
									<LinkContainer to="/hesbw">
										<Nav.Link>HE SBW</Nav.Link>
									</LinkContainer>
								)} */}
							{/* {JSON.stringify(auth) !== "{}" &&
								[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) && (
									// [1, 2].includes(auth.rightid) && (
									<LinkContainer to="/lersbw">
										<Nav.Link>Leveraging SBW</Nav.Link>
									</LinkContainer>
								)} */}
							{/* {JSON.stringify(auth) !== "{}" &&
								[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) && (
									// [1, 2].includes(auth.rightid) && (
									<LinkContainer to="/psesbw">
										<Nav.Link>PSE SBW</Nav.Link>
									</LinkContainer>
								)} */}
							{JSON.stringify(auth) !== "{}" &&
								[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) && (
									// [1, 2].includes(auth.rightid) && (
									<LinkContainer to="/newpmcop">
										<Nav.Link>New PM CoP</Nav.Link>
									</LinkContainer>
								)}
							{JSON.stringify(auth) !== "{}" &&
								[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) && (
									<LinkContainer to="/tcessentials">
										<Nav.Link>Tobacco Control Essentials</Nav.Link>
									</LinkContainer>
								)}

							{JSON.stringify(auth) !== "{}" &&
								[1, 2, 3, 4, 7].includes(auth.rightid) && (
									<LinkContainer to="/pmonboarding">
										<Nav.Link>Awardee Onboarding</Nav.Link>
									</LinkContainer>
								)}
							{JSON.stringify(auth) !== "{}" &&
								[1, 2, 3, 4, 7].includes(auth.rightid) && (
									<LinkContainer to="/phaonboarding">
										<Nav.Link>PHA Onboarding</Nav.Link>
									</LinkContainer>
								)}
							{JSON.stringify(auth) !== "{}" &&
								[1, 2, 3, 4, 7].includes(auth.rightid) && (
									<LinkContainer to="/itp">
										<Nav.Link>ITP</Nav.Link>
									</LinkContainer>
								)}
							{JSON.stringify(auth) !== "{}" &&
								[1, 2, 3, 4, 6, 7].includes(auth.rightid) && (
									<LinkContainer to="/ut">
										<Nav.Link>Universal Tracking</Nav.Link>
									</LinkContainer>
								)}
							{JSON.stringify(auth) !== "{}" &&
								[1, 2, 3, 6].includes(auth.rightid) && (
									<LinkContainer to="/onboardingreport">
										<Nav.Link>Onboarding Report</Nav.Link>
									</LinkContainer>
								)}
							{JSON.stringify(auth) !== "{}" &&
								[1, 6].includes(auth.rightid) && (
									<LinkContainer to="/onboardingapproval">
										<Nav.Link>Onboarding Approvals</Nav.Link>
									</LinkContainer>
								)}

							{/* <LinkContainer to = "/contactus">
                            <Nav.Link>Contact Us</Nav.Link>
                        </LinkContainer> */}
							{JSON.stringify(auth) === "{}" ? (
								<div onClick={() => loginClick()}>
									<Nav.Link className="login-button">Login</Nav.Link>
								</div>
							) : (
								<div onClick={() => logoutClick()}>
									<Nav.Link className="logout-button">Logout</Nav.Link>
								</div>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<LoginModal clicked={login} click={loginClick} />
		</div>
	);
}
