import React from "react";
import Im1 from "../img/guides/tce//Slide1.JPG";
import Im2 from "../img/guides/tce//Slide2.JPG";
import Im3 from "../img/guides/tce//Slide3.JPG";
import Im4 from "../img/guides/tce//Slide4.JPG";
import Im5 from "../img/guides/tce//Slide5.JPG";

export default function UtsWelcome() {
	const style = {
		maxWidth: "800px",
		textAlign: "center",
		margin: "auto",
		boxShadow: "5px 5px 5px grey",
	};

	return (
		<div className="steps-container">
			<div style={{ marginBottom: "20px" }}>
				<h3>Welcome to the Universal Tracking System</h3>
				<p style={{ textAlign: "justify", textJustify: "inter-word" }}>
					The <strong>Universal Tracking System</strong> is the fastest way to
					receive data about your grantees as it pertains to the Tobacco Control
					Training Collaborative's events and other offerings. As events are
					conducted, new data will be added here as soon as possible.
				</p>
				<p>
					To get started, just select from the general data you would like to
					view on the left and then the chart you would like to see. Once a
					chart has been selected, you will be granted the option to view the
					data in tabular form or visualization form.
				</p>
				<p>
					Please reach out to Deshaune Bailey at{" "}
					<a href="mailto:djbailey@logicalintegrations.com">
						djbailey@logicalintegrations.com
					</a>{" "}
					if you have any requests for additions or you encounter any problems.
				</p>
			</div>
		</div>
	);
}
