import React, {useState,useEffect,useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import AuthContext from '../context/authprovider';
export default function LoginModal(props){
    const [show, setShow] = useState(false);
    const [email,setEmail]=useState("");
    const [password,setPassword]=useState("");
    const [title,setTitle]=useState("");
    const [fname,setFname]=useState("");
    const [lname,setLname]=useState("");
    const [confirmPassword,setConfirmPassword]=useState("");
    const [role,setRole]=useState("0");
    const [org,setOrg]=useState("0");
    const [orgs,setOrgs]=useState([]);
    const [hire,setHire]=useState("");
    const [roles,setRoles]=useState([]);
    const [register,setRegister]=useState(false);
    const [reset,setReset]=useState(false);
    const [loggedinonce,setLoggedinonce]=useState(false);
    const [error,setError]=useState(false);
    const [errorMessage,setErrorMessage]=useState("Please fill out all the information below.");
    const [message,setMessage]=useState("");
    const {auth, setAuth} = useContext(AuthContext);
    const handleClose = () => {
        setShow(false);
        setLoggedinonce(false);
        props.click();
    }

    // useEffect(() => {
    //     const keyDownHandler = event => {
    //     //   console.log('User pressed: ', event.key);
    
    //       if (event.key === 'Enter') {
    //         event.preventDefault();
    
    //         handleSubmit();
    //       }
    //     };
    
    //     document.addEventListener('keydown', keyDownHandler);
    
    //     // 👇️ clean up event listener
    //     return () => {
    //       document.removeEventListener('keydown', keyDownHandler);
    //     };
    //   }, []);

    const handleEmail=(event)=>{
        setEmail(event.target.value)
    }
    const handlePassword=(event)=>{
        setPassword(event.target.value)
    }
    const handleTitle=(event)=>{
        setTitle(event.target.value)
    }
    const handleHire=(event)=>{
        setHire(event.target.value)
    }
    const handlefname=(event)=>{
        setFname(event.target.value)
    }
    const handlelname=(event)=>{
        setLname(event.target.value)
    }
    const handleRole=(event)=>{
        setRole(event.target.value)
    }
    const handleOrg=(event)=>{
        setOrg(event.target.value)
    }
    const handleConfirmPassword=(event)=>{
        setConfirmPassword(event.target.value)
    }
    const handleShow = () => setShow(true);
    
    useEffect(()=>{
        setShow(props.clicked);        
    },[props.clicked])
    useEffect(()=>{
        fetch('https://www.api.tctcollab.com/info/roles')
        .then((results)=>results.json())
        .then((data)=>{
            setRoles(data);
            setError(false);
        })
        fetch('https://www.api.tctcollab.com/info/orgs')
        .then((results)=>results.json())
        .then((data)=>{
            setOrgs(data);
            setError(false);
        })
    },[])
    const handleSubmit=()=>{
       if(reset===true){
            if(email!==''){
                fetch(`https://www.api.tctcollab.com/user/passwordresetemail/${email}`)
                .then((results)=>results.json())
                .then((data)=>{
                    if(data.message=="success"){
                        // console.log(data);
                        setMessage("An email was sent to your inbox with further instructions. Thank you!")
                        setError(false);
                    }
                    else{
                        setErrorMessage("This email is not registered in our system");
                        setError(true);
                    }
                    
                }) 
            }
       }
       else{
        if(register===true){
            if(password===confirmPassword && title!=="" && email!=="" && password!=="" && role!==0 && fname!=="" && lname!==""){
                let data={
                    password,
                    title,
                    email,
                    role,
                    hire,
                    fname,
                    lname,
                    org
                }
                fetch('https://www.api.tctcollab.com/user/register', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                .then((results)=>results.json())
                .then((data)=>{
                    if(data.response=="success"){
                        setAuth(data.user)
                        localStorage.setItem("user", JSON.stringify(data.user));
                        // console.log(data.user)
                        setError(false);
                        handleClose();
                    }
                    else{                                                    
                        setErrorMessage(data.message)
                        setError(true);                        
                        
                    }
                    
                })
            }
            else{
                setErrorMessage("Please fill out all the information below.")
                setError(true);
            }
        }
        else if(loggedinonce===true){
            if(title!=="" && role!==0){
                let data={
                    password,
                    title,
                    email,
                    role,
                    hire,
                    userid:auth.userid
                }
                // console.log(data);
                fetch('https://www.api.tctcollab.com/user/addinfo', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                .then((results)=>results.json())
                .then((data)=>{
                    if(data.response=="success"){
                        // console.log(data.user)
                        setError(false);
                        handleClose();
                            // console.log(data);
                    }
                    else{
                        setErrorMessage(data.message)
                        setError(true);
                        
                    }
                    
                })
            }
            else{
                setErrorMessage("Please fill out all the information below.")
                setError(true);
            }
        }
        else{
            if(email!=="" && password!==""){
                let data={
                    password,
                    title,
                    email,
                    role,
                    hire
                }
                fetch('https://www.api.tctcollab.com/user/login', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                .then((results)=>results.json())
                .then((data)=>{
                    // console.log(data);
                    if(data.response=="success"){
                        setAuth(data.user)
                        localStorage.setItem("user", JSON.stringify(data.user));
                        
                        // console.log(data.user)
                        setError(false);
                        // console.log(data.user.addedinfo);
                        if(data.user.addedinfo==0){
                            setLoggedinonce(true);
                        }else{
                            handleClose();
                            // console.log(data);
                        }
                    }
                    setErrorMessage(data.message)
                    setError(true);
                    
                })
            }
            else{
                setErrorMessage("Please fill out all the information below.")
                setError(true);
            }
        }
    }
    }

    return(
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{!reset?!register? loggedinonce? "Please Enter Additional Information Below":"Login Below" : "Register Below":"Please enter your email below to reset your password."}</Modal.Title>
          
        </Modal.Header>
        <Modal.Body>
            {error&&<div style={{color:"red",fontWeight:"bold",marginBottom:"10px"}}>{errorMessage}</div>}
            {message!==""&&<div style={{color:"green",fontWeight:"bold",marginBottom:"10px"}}>{message}</div>}
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" onChange={handleEmail} value={email}/>
            </Form.Group>

            {!reset && <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" onChange={handlePassword} value={password}/>
            </Form.Group>
            }
            {register &&
            <div>
                <Form.Group className="mb-3" controlId="formConfirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={handleConfirmPassword} value={confirmPassword}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" placeholder="First Name" onChange={handlefname} value={fname}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" placeholder="Last Name" onChange={handlelname} value={lname}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Job Title</Form.Label>
                    <Form.Control type="text" placeholder="Enter your job title" onChange={handleTitle} value={title}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Tobacco Control Role</Form.Label>
                    <Form.Select onChange={handleRole}>
                        <option value="0">Tobacco Control Role</option>
                        {roles.map((role)=>{
                            return(
                                <option value={role.roleid}>{role.descr}</option>
                            )
                        })}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Organization</Form.Label>
                    <Form.Select  onChange={handleOrg}>
                        <option value="0">Organization</option>
                        {orgs.map((org)=>{
                            return(
                                <option value={org.orgid}>{org.descr}</option>
                            )
                        })}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formHire">
                    <Form.Label>Hire Date</Form.Label>
                    <Form.Control type="date" placeholder="Hire Date" onChange={handleHire} value={hire}/>
                </Form.Group>
            </div>
            }
            {loggedinonce &&
            <div>
                <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Job Title</Form.Label>
                    <Form.Control type="text" placeholder="Enter your job title" onChange={handleTitle} value={title}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Tobacco Control Role</Form.Label>
                    <Form.Select onChange={handleRole}>
                        <option>Tobacco Control Role</option>
                        {roles.map((role)=>{
                            return(
                                <option value={role.roleid}>{role.descr}</option>
                            )
                        })}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formHire">
                    <Form.Label>Hire Date</Form.Label>
                    <Form.Control type="date" placeholder="Hire Date" onChange={handleHire} value={hire}/>
                </Form.Group>
            </div>
            }
        </Modal.Body>
        <Modal.Footer>
        <div style={{cursor:"pointer"}}onClick={()=>{setReset(true); setRegister(false);}}> Reset Password</div>
        {!register? loggedinonce ? null :
          <Button variant="primary" onClick={()=>{setReset(false);setRegister(true);setMessage("")}}>
            Switch to Register
          </Button>
          :
          <Button variant="primary" onClick={()=>{setReset(false);setRegister(false);setMessage("")}}>
            Switch to Login
          </Button>
            }
          <Button variant="success" onClick={()=>handleSubmit()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    )
}
