import React from "react";
import { useEffect, useState } from "react";
export default function MyProgressBar(props) {
	const [barColor, setBarColor] = useState("");
	const [barPercentage, setBarPercentage] = useState(0);
	const [pColor, setPColor] = useState("black");

	const barContainerStyle = {
		height: "30px",
		backgroundColor: "rgb(250,250,250)",
		width: "100%",
		borderRadius: "15px",
		overflow: "hidden",
		boxShadow: "inset 0 0 5px grey",
	};
	const barStyle = {
		paddingTop: "3px",
		paddingBottom: "3px",
		minHeight: "100%",
		width: barPercentage + "%",
		backgroundColor: barColor,
		boxSizing: "border-box",
		borderRadius: "0 15px 15px 0",
		boxShadow: "inset 0 0 3px grey",
		transition: "width .5s",
	};
	const percentStyle = {
		fontWeight: "bold",
		color: pColor,
	};

	useEffect(() => {
		setTimeout(() => {
			setBarPercentage(props.percentage);
		}, 500);
		props.percentage < 20
			? setBarColor("red")
			: props.percentage < 80
			? setBarColor("yellow")
			: setBarColor("green");
		props.percentage >= 80 ? setPColor("white") : setPColor("black");
	}, []);
	useEffect(() => {
		setTimeout(() => {
			setBarPercentage(props.percentage);
		}, 500);
		setBarPercentage(props.percentage);
		props.percentage < 20
			? setBarColor("red")
			: props.percentage < 80
			? setBarColor("yellow")
			: setBarColor("green");
		props.percentage >= 80 ? setPColor("white") : setPColor("black");
	}, [props.percentage]);

	return (
		<div
			to={`${props.id}`}
			className="bar-container m-auto"
			style={barContainerStyle}
		>
			<div className="bar" style={barStyle}>
				<div className="percent" style={percentStyle}>
					{barPercentage + "%"}
				</div>
			</div>
		</div>
	);
}
